import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  geography: { type: 'Point'; coordinates: [number, number] };
  geometry: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ClientComplaintSendAttachment = {
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
};

export type ClientComplaintSendInput = {
  attachments: Array<ClientComplaintSendAttachment>;
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export type ClientComplaintSendOutput = {
  __typename?: 'ClientComplaintSendOutput';
  result: Scalars['Boolean'];
};

export type ClientContactSendInput = {
  email?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ClientContactSendOutput = {
  __typename?: 'ClientContactSendOutput';
  result: Scalars['Boolean'];
};

export type ClientLogoutOutput = {
  __typename?: 'ClientLogoutOutput';
  result: Scalars['String'];
};

export type ClientVerifyKdrOutput = {
  __typename?: 'ClientVerifyKdrOutput';
  valid: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type OrderAddCollectionInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  clientNotes?: InputMaybe<Scalars['String']>;
  clientQuarantine?: InputMaybe<Scalars['Boolean']>;
  collectionDate: Scalars['String'];
  location: Array<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  pesel?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  tests: Array<OrderAddTestInput>;
};

export type OrderAddConsultationInput = {
  consultationId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  preferredDate: Scalars['String'];
  profile: OrderAddProfileInput;
};

export type OrderAddInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientConsent1?: InputMaybe<Scalars['Boolean']>;
  clientConsent2?: InputMaybe<Scalars['Boolean']>;
  clientConsent3?: InputMaybe<Scalars['Boolean']>;
  clientNotes?: InputMaybe<Scalars['String']>;
  clientQuarantine?: InputMaybe<Scalars['Boolean']>;
  collections?: InputMaybe<Array<OrderAddCollectionInput>>;
  consultations?: InputMaybe<Array<OrderAddConsultationInput>>;
  date?: InputMaybe<Scalars['String']>;
  discountCodes: Array<Scalars['String']>;
  location?: InputMaybe<Array<Scalars['Float']>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  tests?: InputMaybe<Array<OrderAddTestInput>>;
  trainings?: InputMaybe<Array<OrderAddTrainingInput>>;
};

export type OrderAddOutput = {
  __typename?: 'OrderAddOutput';
  id: Scalars['Int'];
  paymentToken: Scalars['String'];
};

export type OrderAddProfileInput = {
  firstName: Scalars['String'];
  identity: Scalars['jsonb'];
  lastName: Scalars['String'];
};

export type OrderAddTestInput = {
  profile: OrderAddProfileInput;
  testId: Scalars['Int'];
};

export type OrderAddTrainingInput = {
  trainingId: Scalars['Int'];
};

export type OrderCostCollectionInput = {
  collectionDate?: InputMaybe<Scalars['String']>;
  location: Array<Scalars['Float']>;
  tests: Array<OrderAddTestInput>;
};

export type OrderCostCollectionOutput = {
  __typename?: 'OrderCostCollectionOutput';
  collectionCost: Scalars['Float'];
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost: Scalars['Float'];
  collectionKitCostBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCost: Scalars['Float'];
  deliveryCostBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCostPerKm: Scalars['Float'];
  deliveryDistance: Scalars['Int'];
  tests: Array<OrderCostTestOutput>;
};

export type OrderCostConsultationInput = {
  consultationId: Scalars['Int'];
  preferredDate?: InputMaybe<Scalars['String']>;
  profile: OrderAddProfileInput;
};

export type OrderCostConsultationOutput = {
  __typename?: 'OrderCostConsultationOutput';
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export type OrderCostDiscountOutput = {
  __typename?: 'OrderCostDiscountOutput';
  code: Scalars['String'];
  id: Scalars['Int'];
  percent: Scalars['Float'];
  type: Scalars['String'];
};

export type OrderCostInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  collections?: InputMaybe<Array<OrderCostCollectionInput>>;
  consultations?: InputMaybe<Array<OrderCostConsultationInput>>;
  date?: InputMaybe<Scalars['String']>;
  discountCodes: Array<Scalars['String']>;
  location?: InputMaybe<Array<Scalars['Float']>>;
  postalCode?: InputMaybe<Scalars['String']>;
  tests?: InputMaybe<Array<OrderAddTestInput>>;
  trainings?: InputMaybe<Array<OrderAddTrainingInput>>;
};

export type OrderCostOutput = {
  __typename?: 'OrderCostOutput';
  collectionCost: Scalars['Float'];
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost: Scalars['Float'];
  collectionKitCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collections: Array<OrderCostCollectionOutput>;
  consultations: Array<OrderCostConsultationOutput>;
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCost: Scalars['Float'];
  deliveryCostBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryCostPerKm: Scalars['Float'];
  deliveryDistance: Scalars['Int'];
  discounts: Array<OrderCostDiscountOutput>;
  tests: Array<OrderCostTestOutput>;
  trainings: Array<OrderCostTrainingOutput>;
};

export type OrderCostProfileOutput = {
  __typename?: 'OrderCostProfileOutput';
  name: Scalars['String'];
  pesel: Scalars['String'];
};

export type OrderCostTestOutput = {
  __typename?: 'OrderCostTestOutput';
  collectionPointId: Scalars['Int'];
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  deliveryOrder: Scalars['Int'];
  id: Scalars['Int'];
  laboratoryId: Scalars['Int'];
  profile?: Maybe<OrderCostProfileOutput>;
};

export type OrderCostTrainingOutput = {
  __typename?: 'OrderCostTrainingOutput';
  cost: Scalars['Float'];
  costBeforeDiscount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export type PingOutput = {
  __typename?: 'PingOutput';
  pong: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type AvailableLaboratoryTests_Test_Args = {
  point?: InputMaybe<Scalars['geography']>;
};

export type Available_Laboratory_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type Available_Test_Tag_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

/** columns and relationships of "client" */
export type Client = {
  __typename?: 'client';
  /** An array relationship */
  clientProfiles: Array<Client_Profile>;
  email: Scalars['String'];
  id: Scalars['Int'];
  newsletterConsent: Scalars['Boolean'];
  pushConsent: Scalars['Boolean'];
};


/** columns and relationships of "client" */
export type ClientClientProfilesArgs = {
  distinct_on?: InputMaybe<Array<Client_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Profile_Order_By>>;
  where?: InputMaybe<Client_Profile_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  clientProfiles?: InputMaybe<Client_Profile_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  newsletterConsent?: InputMaybe<Boolean_Comparison_Exp>;
  pushConsent?: InputMaybe<Boolean_Comparison_Exp>;
};

/** columns and relationships of "client_favourite_test" */
export type Client_Favourite_Test = {
  __typename?: 'client_favourite_test';
  clientId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** aggregated selection of "client_favourite_test" */
export type Client_Favourite_Test_Aggregate = {
  __typename?: 'client_favourite_test_aggregate';
  aggregate?: Maybe<Client_Favourite_Test_Aggregate_Fields>;
  nodes: Array<Client_Favourite_Test>;
};

export type Client_Favourite_Test_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Favourite_Test_Aggregate_Bool_Exp_Count>;
};

export type Client_Favourite_Test_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_favourite_test" */
export type Client_Favourite_Test_Aggregate_Fields = {
  __typename?: 'client_favourite_test_aggregate_fields';
  avg?: Maybe<Client_Favourite_Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Client_Favourite_Test_Max_Fields>;
  min?: Maybe<Client_Favourite_Test_Min_Fields>;
  stddev?: Maybe<Client_Favourite_Test_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Favourite_Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Favourite_Test_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Favourite_Test_Sum_Fields>;
  var_pop?: Maybe<Client_Favourite_Test_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Favourite_Test_Var_Samp_Fields>;
  variance?: Maybe<Client_Favourite_Test_Variance_Fields>;
};


/** aggregate fields of "client_favourite_test" */
export type Client_Favourite_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_favourite_test" */
export type Client_Favourite_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Favourite_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Favourite_Test_Max_Order_By>;
  min?: InputMaybe<Client_Favourite_Test_Min_Order_By>;
  stddev?: InputMaybe<Client_Favourite_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Favourite_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Favourite_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Favourite_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Favourite_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Favourite_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Favourite_Test_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Client_Favourite_Test_Avg_Fields = {
  __typename?: 'client_favourite_test_avg_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Avg_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_favourite_test". All fields are combined with a logical 'AND'. */
export type Client_Favourite_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Favourite_Test_Bool_Exp>>;
  _not?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Favourite_Test_Bool_Exp>>;
  clientId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_favourite_test" */
export enum Client_Favourite_Test_Constraint {
  /** unique or primary key constraint on columns "test_id", "client_id" */
  ClientFavouriteTestClientIdTestIdKey = 'client_favourite_test_client_id_test_id_key',
  /** unique or primary key constraint on columns "id" */
  ClientFavouriteTestPkey = 'client_favourite_test_pkey'
}

/** input type for inserting data into table "client_favourite_test" */
export type Client_Favourite_Test_Insert_Input = {
  clientId?: InputMaybe<Scalars['Int']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Client_Favourite_Test_Max_Fields = {
  __typename?: 'client_favourite_test_max_fields';
  clientId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Favourite_Test_Min_Fields = {
  __typename?: 'client_favourite_test_min_fields';
  clientId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_favourite_test" */
export type Client_Favourite_Test_Mutation_Response = {
  __typename?: 'client_favourite_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Favourite_Test>;
};

/** on_conflict condition type for table "client_favourite_test" */
export type Client_Favourite_Test_On_Conflict = {
  constraint: Client_Favourite_Test_Constraint;
  update_columns?: Array<Client_Favourite_Test_Update_Column>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};

/** Ordering options when selecting data from "client_favourite_test". */
export type Client_Favourite_Test_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "client_favourite_test" */
export enum Client_Favourite_Test_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  TestId = 'testId'
}

/** aggregate stddev on columns */
export type Client_Favourite_Test_Stddev_Fields = {
  __typename?: 'client_favourite_test_stddev_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Stddev_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Favourite_Test_Stddev_Pop_Fields = {
  __typename?: 'client_favourite_test_stddev_pop_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Stddev_Pop_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Favourite_Test_Stddev_Samp_Fields = {
  __typename?: 'client_favourite_test_stddev_samp_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Stddev_Samp_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_favourite_test" */
export type Client_Favourite_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Favourite_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Favourite_Test_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Client_Favourite_Test_Sum_Fields = {
  __typename?: 'client_favourite_test_sum_fields';
  clientId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Sum_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "client_favourite_test" (current role has no relevant permissions) */
export enum Client_Favourite_Test_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type Client_Favourite_Test_Var_Pop_Fields = {
  __typename?: 'client_favourite_test_var_pop_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Var_Pop_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Favourite_Test_Var_Samp_Fields = {
  __typename?: 'client_favourite_test_var_samp_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Var_Samp_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Favourite_Test_Variance_Fields = {
  __typename?: 'client_favourite_test_variance_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "client_favourite_test" */
export type Client_Favourite_Test_Variance_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  __typename?: 'client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** Ordering options when selecting data from "client". */
export type Client_Order_By = {
  clientProfiles_aggregate?: InputMaybe<Client_Profile_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  newsletterConsent?: InputMaybe<Order_By>;
  pushConsent?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client */
export type Client_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "client_profile" */
export type Client_Profile = {
  __typename?: 'client_profile';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  client: Client;
  clientId: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  identity: Scalars['jsonb'];
  isMain?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  location?: Maybe<Scalars['geography']>;
  name?: Maybe<Scalars['String']>;
  pesel?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "client_profile" */
export type Client_ProfileIdentityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "client_profile" */
export type Client_Profile_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Profile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Profile_Max_Order_By>;
  min?: InputMaybe<Client_Profile_Min_Order_By>;
  stddev?: InputMaybe<Client_Profile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Profile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Profile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Profile_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Profile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Profile_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Profile_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Client_Profile_Append_Input = {
  identity?: InputMaybe<Scalars['jsonb']>;
};

/** order by avg() on columns of table "client_profile" */
export type Client_Profile_Avg_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_profile". All fields are combined with a logical 'AND'. */
export type Client_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Profile_Bool_Exp>>;
  _not?: InputMaybe<Client_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Profile_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  client?: InputMaybe<Client_Bool_Exp>;
  clientId?: InputMaybe<Int_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity?: InputMaybe<Jsonb_Comparison_Exp>;
  isMain?: InputMaybe<Boolean_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pesel?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_profile" */
export enum Client_Profile_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientProfilePkey = 'client_profile_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Client_Profile_Delete_At_Path_Input = {
  identity?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Client_Profile_Delete_Elem_Input = {
  identity?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Client_Profile_Delete_Key_Input = {
  identity?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "client_profile" */
export type Client_Profile_Inc_Input = {
  clientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_profile" */
export type Client_Profile_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  identity?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  pesel?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "client_profile" */
export type Client_Profile_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pesel?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "client_profile" */
export type Client_Profile_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pesel?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_profile" */
export type Client_Profile_Mutation_Response = {
  __typename?: 'client_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Profile>;
};

/** on_conflict condition type for table "client_profile" */
export type Client_Profile_On_Conflict = {
  constraint: Client_Profile_Constraint;
  update_columns?: Array<Client_Profile_Update_Column>;
  where?: InputMaybe<Client_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "client_profile". */
export type Client_Profile_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  client?: InputMaybe<Client_Order_By>;
  clientId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  isMain?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pesel?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_profile */
export type Client_Profile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Client_Profile_Prepend_Input = {
  identity?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "client_profile" */
export enum Client_Profile_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  IsMain = 'isMain',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Pesel = 'pesel',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostalCode = 'postalCode'
}

/** input type for updating data in table "client_profile" */
export type Client_Profile_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  identity?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  pesel?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "client_profile" */
export type Client_Profile_Stddev_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "client_profile" */
export type Client_Profile_Stddev_Pop_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "client_profile" */
export type Client_Profile_Stddev_Samp_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_profile" */
export type Client_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Profile_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['jsonb']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  pesel?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "client_profile" */
export type Client_Profile_Sum_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "client_profile" */
export enum Client_Profile_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Identity = 'identity',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Pesel = 'pesel',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostalCode = 'postalCode'
}

export type Client_Profile_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Client_Profile_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Client_Profile_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Client_Profile_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Client_Profile_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Profile_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Client_Profile_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Profile_Bool_Exp;
};

/** order by var_pop() on columns of table "client_profile" */
export type Client_Profile_Var_Pop_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "client_profile" */
export type Client_Profile_Var_Samp_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "client_profile" */
export type Client_Profile_Variance_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  NewsletterConsent = 'newsletterConsent',
  /** column name */
  PushConsent = 'pushConsent'
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  newsletterConsent?: InputMaybe<Scalars['Boolean']>;
  pushConsent?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "client" */
export type Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  newsletterConsent?: InputMaybe<Scalars['Boolean']>;
  pushConsent?: InputMaybe<Scalars['Boolean']>;
};

export type Client_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Bool_Exp;
};

/** columns and relationships of "consultation" */
export type Consultation = {
  __typename?: 'consultation';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "consultation_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "consultation_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  orderConsultations: Array<Order_Consultation>;
  /** An aggregate relationship */
  orderConsultations_aggregate: Order_Consultation_Aggregate;
  price: Scalars['numeric'];
  svgIcon?: Maybe<Scalars['String']>;
};


/** columns and relationships of "consultation" */
export type ConsultationOrderConsultationsArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


/** columns and relationships of "consultation" */
export type ConsultationOrderConsultations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};

/** aggregated selection of "consultation" */
export type Consultation_Aggregate = {
  __typename?: 'consultation_aggregate';
  aggregate?: Maybe<Consultation_Aggregate_Fields>;
  nodes: Array<Consultation>;
};

/** aggregate fields of "consultation" */
export type Consultation_Aggregate_Fields = {
  __typename?: 'consultation_aggregate_fields';
  avg?: Maybe<Consultation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Consultation_Max_Fields>;
  min?: Maybe<Consultation_Min_Fields>;
  stddev?: Maybe<Consultation_Stddev_Fields>;
  stddev_pop?: Maybe<Consultation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Consultation_Stddev_Samp_Fields>;
  sum?: Maybe<Consultation_Sum_Fields>;
  var_pop?: Maybe<Consultation_Var_Pop_Fields>;
  var_samp?: Maybe<Consultation_Var_Samp_Fields>;
  variance?: Maybe<Consultation_Variance_Fields>;
};


/** aggregate fields of "consultation" */
export type Consultation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Consultation_Avg_Fields = {
  __typename?: 'consultation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "consultation". All fields are combined with a logical 'AND'. */
export type Consultation_Bool_Exp = {
  _and?: InputMaybe<Array<Consultation_Bool_Exp>>;
  _not?: InputMaybe<Consultation_Bool_Exp>;
  _or?: InputMaybe<Array<Consultation_Bool_Exp>>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orderConsultations?: InputMaybe<Order_Consultation_Bool_Exp>;
  orderConsultations_aggregate?: InputMaybe<Order_Consultation_Aggregate_Bool_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  svgIcon?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Consultation_Max_Fields = {
  __typename?: 'consultation_max_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "consultation_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "consultation_name_i18n" */
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  svgIcon?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Consultation_Min_Fields = {
  __typename?: 'consultation_min_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "consultation_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "consultation_name_i18n" */
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  svgIcon?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "consultation". */
export type Consultation_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  orderConsultations_aggregate?: InputMaybe<Order_Consultation_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  svgIcon?: InputMaybe<Order_By>;
};

/** select columns of table "consultation" */
export enum Consultation_Select_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  SvgIcon = 'svgIcon'
}

/** aggregate stddev on columns */
export type Consultation_Stddev_Fields = {
  __typename?: 'consultation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Consultation_Stddev_Pop_Fields = {
  __typename?: 'consultation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Consultation_Stddev_Samp_Fields = {
  __typename?: 'consultation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "consultation" */
export type Consultation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consultation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consultation_Stream_Cursor_Value_Input = {
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  svgIcon?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Consultation_Sum_Fields = {
  __typename?: 'consultation_sum_fields';
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Consultation_Var_Pop_Fields = {
  __typename?: 'consultation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Consultation_Var_Samp_Fields = {
  __typename?: 'consultation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Consultation_Variance_Fields = {
  __typename?: 'consultation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  orderTestResults: Array<Order_Test_Result>;
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
};


/** columns and relationships of "file" */
export type FileOrderTestResultsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orderTestResults?: InputMaybe<Order_Test_Result_Bool_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  orderTestResults_aggregate?: InputMaybe<Order_Test_Result_Aggregate_Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

export type IsAvailable_Test_Args = {
  point?: InputMaybe<Scalars['geography']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "laboratory" */
export type Laboratory = {
  __typename?: 'laboratory';
  id: Scalars['Int'];
  /** An array relationship */
  laboratoryTests: Array<Laboratory_Test>;
  /** An aggregate relationship */
  laboratoryTests_aggregate: Laboratory_Test_Aggregate;
  /** An object relationship */
  logoFile?: Maybe<File>;
  logoFileId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
};


/** columns and relationships of "laboratory" */
export type LaboratoryLaboratoryTestsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "laboratory" */
export type LaboratoryLaboratoryTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};

export type Laboratory_Aggregate = {
  __typename?: 'laboratory_aggregate';
  aggregate?: Maybe<Laboratory_Aggregate_Fields>;
  nodes: Array<Laboratory>;
};

/** aggregate fields of "laboratory" */
export type Laboratory_Aggregate_Fields = {
  __typename?: 'laboratory_aggregate_fields';
  avg?: Maybe<Laboratory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Laboratory_Max_Fields>;
  min?: Maybe<Laboratory_Min_Fields>;
  stddev?: Maybe<Laboratory_Stddev_Fields>;
  stddev_pop?: Maybe<Laboratory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Laboratory_Stddev_Samp_Fields>;
  sum?: Maybe<Laboratory_Sum_Fields>;
  var_pop?: Maybe<Laboratory_Var_Pop_Fields>;
  var_samp?: Maybe<Laboratory_Var_Samp_Fields>;
  variance?: Maybe<Laboratory_Variance_Fields>;
};


/** aggregate fields of "laboratory" */
export type Laboratory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Laboratory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "laboratory" */
export type Laboratory_Aggregate_Order_By = {
  avg?: InputMaybe<Laboratory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Laboratory_Max_Order_By>;
  min?: InputMaybe<Laboratory_Min_Order_By>;
  stddev?: InputMaybe<Laboratory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Laboratory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Laboratory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Laboratory_Sum_Order_By>;
  var_pop?: InputMaybe<Laboratory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Laboratory_Var_Samp_Order_By>;
  variance?: InputMaybe<Laboratory_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Laboratory_Avg_Fields = {
  __typename?: 'laboratory_avg_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "laboratory" */
export type Laboratory_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "laboratory". All fields are combined with a logical 'AND'. */
export type Laboratory_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratoryTests?: InputMaybe<Laboratory_Test_Bool_Exp>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp>;
  logoFile?: InputMaybe<File_Bool_Exp>;
  logoFileId?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Laboratory_Max_Fields = {
  __typename?: 'laboratory_max_fields';
  id?: Maybe<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "laboratory" */
export type Laboratory_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Laboratory_Min_Fields = {
  __typename?: 'laboratory_min_fields';
  id?: Maybe<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "laboratory" */
export type Laboratory_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "laboratory". */
export type Laboratory_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  logoFile?: InputMaybe<File_Order_By>;
  logoFileId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory" */
export enum Laboratory_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logoFileId',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'shortName'
}

/** aggregate stddev on columns */
export type Laboratory_Stddev_Fields = {
  __typename?: 'laboratory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "laboratory" */
export type Laboratory_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Laboratory_Stddev_Pop_Fields = {
  __typename?: 'laboratory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "laboratory" */
export type Laboratory_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Laboratory_Stddev_Samp_Fields = {
  __typename?: 'laboratory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "laboratory" */
export type Laboratory_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "laboratory" */
export type Laboratory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  logoFileId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Laboratory_Sum_Fields = {
  __typename?: 'laboratory_sum_fields';
  id?: Maybe<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "laboratory" */
export type Laboratory_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** columns and relationships of "laboratory_test" */
export type Laboratory_Test = {
  __typename?: 'laboratory_test';
  id: Scalars['Int'];
  /** An object relationship */
  laboratory: Laboratory;
  laboratoryId: Scalars['Int'];
  price: Scalars['numeric'];
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** aggregated selection of "laboratory_test" */
export type Laboratory_Test_Aggregate = {
  __typename?: 'laboratory_test_aggregate';
  aggregate?: Maybe<Laboratory_Test_Aggregate_Fields>;
  nodes: Array<Laboratory_Test>;
};

export type Laboratory_Test_Aggregate_Bool_Exp = {
  count?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp_Count>;
};

export type Laboratory_Test_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Laboratory_Test_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "laboratory_test" */
export type Laboratory_Test_Aggregate_Fields = {
  __typename?: 'laboratory_test_aggregate_fields';
  avg?: Maybe<Laboratory_Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Laboratory_Test_Max_Fields>;
  min?: Maybe<Laboratory_Test_Min_Fields>;
  stddev?: Maybe<Laboratory_Test_Stddev_Fields>;
  stddev_pop?: Maybe<Laboratory_Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Laboratory_Test_Stddev_Samp_Fields>;
  sum?: Maybe<Laboratory_Test_Sum_Fields>;
  var_pop?: Maybe<Laboratory_Test_Var_Pop_Fields>;
  var_samp?: Maybe<Laboratory_Test_Var_Samp_Fields>;
  variance?: Maybe<Laboratory_Test_Variance_Fields>;
};


/** aggregate fields of "laboratory_test" */
export type Laboratory_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "laboratory_test" */
export type Laboratory_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Laboratory_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Laboratory_Test_Max_Order_By>;
  min?: InputMaybe<Laboratory_Test_Min_Order_By>;
  stddev?: InputMaybe<Laboratory_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Laboratory_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Laboratory_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Laboratory_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Laboratory_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Laboratory_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Laboratory_Test_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Laboratory_Test_Avg_Fields = {
  __typename?: 'laboratory_test_avg_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "laboratory_test" */
export type Laboratory_Test_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "laboratory_test". All fields are combined with a logical 'AND'. */
export type Laboratory_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Test_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Test_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratory?: InputMaybe<Laboratory_Bool_Exp>;
  laboratoryId?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Laboratory_Test_Max_Fields = {
  __typename?: 'laboratory_test_max_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "laboratory_test" */
export type Laboratory_Test_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Laboratory_Test_Min_Fields = {
  __typename?: 'laboratory_test_min_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "laboratory_test" */
export type Laboratory_Test_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "laboratory_test". */
export type Laboratory_Test_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratory?: InputMaybe<Laboratory_Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory_test" */
export enum Laboratory_Test_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LaboratoryId = 'laboratoryId',
  /** column name */
  Price = 'price',
  /** column name */
  TestId = 'testId'
}

/** aggregate stddev on columns */
export type Laboratory_Test_Stddev_Fields = {
  __typename?: 'laboratory_test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Laboratory_Test_Stddev_Pop_Fields = {
  __typename?: 'laboratory_test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Laboratory_Test_Stddev_Samp_Fields = {
  __typename?: 'laboratory_test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "laboratory_test" */
export type Laboratory_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Test_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  laboratoryId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Laboratory_Test_Sum_Fields = {
  __typename?: 'laboratory_test_sum_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "laboratory_test" */
export type Laboratory_Test_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Laboratory_Test_Var_Pop_Fields = {
  __typename?: 'laboratory_test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "laboratory_test" */
export type Laboratory_Test_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Laboratory_Test_Var_Samp_Fields = {
  __typename?: 'laboratory_test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "laboratory_test" */
export type Laboratory_Test_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Laboratory_Test_Variance_Fields = {
  __typename?: 'laboratory_test_variance_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "laboratory_test" */
export type Laboratory_Test_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Laboratory_Var_Pop_Fields = {
  __typename?: 'laboratory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "laboratory" */
export type Laboratory_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Laboratory_Var_Samp_Fields = {
  __typename?: 'laboratory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "laboratory" */
export type Laboratory_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Laboratory_Variance_Fields = {
  __typename?: 'laboratory_variance_fields';
  id?: Maybe<Scalars['Float']>;
  logoFileId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "laboratory" */
export type Laboratory_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  logoFileId?: InputMaybe<Order_By>;
};

/** columns and relationships of "material" */
export type Material = {
  __typename?: 'material';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  nurseMaterials: Array<Nurse_Material>;
};


/** columns and relationships of "material" */
export type MaterialNurseMaterialsArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Material_Order_By>>;
  where?: InputMaybe<Nurse_Material_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "material". All fields are combined with a logical 'AND'. */
export type Material_Bool_Exp = {
  _and?: InputMaybe<Array<Material_Bool_Exp>>;
  _not?: InputMaybe<Material_Bool_Exp>;
  _or?: InputMaybe<Array<Material_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nurseMaterials?: InputMaybe<Nurse_Material_Bool_Exp>;
};

/** Ordering options when selecting data from "material". */
export type Material_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nurseMaterials_aggregate?: InputMaybe<Nurse_Material_Aggregate_Order_By>;
};

/** select columns of table "material" */
export enum Material_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "material" */
export type Material_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Material_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Material_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  client_complaint_send?: Maybe<ClientComplaintSendOutput>;
  client_contact_send?: Maybe<ClientContactSendOutput>;
  client_logout: ClientLogoutOutput;
  client_order_add?: Maybe<OrderAddOutput>;
  client_verify_kdr?: Maybe<ClientVerifyKdrOutput>;
  /** delete data from the table: "client_favourite_test" */
  delete_client_favourite_test?: Maybe<Client_Favourite_Test_Mutation_Response>;
  /** delete single row from the table: "client_favourite_test" */
  delete_client_favourite_test_by_pk?: Maybe<Client_Favourite_Test>;
  /** delete data from the table: "client_profile" */
  delete_client_profile?: Maybe<Client_Profile_Mutation_Response>;
  /** delete single row from the table: "client_profile" */
  delete_client_profile_by_pk?: Maybe<Client_Profile>;
  /** insert data into the table: "client_favourite_test" */
  insert_client_favourite_test?: Maybe<Client_Favourite_Test_Mutation_Response>;
  /** insert a single row into the table: "client_favourite_test" */
  insert_client_favourite_test_one?: Maybe<Client_Favourite_Test>;
  /** insert data into the table: "client_profile" */
  insert_client_profile?: Maybe<Client_Profile_Mutation_Response>;
  /** insert a single row into the table: "client_profile" */
  insert_client_profile_one?: Maybe<Client_Profile>;
  /** insert data into the table: "order_state" */
  insert_order_state?: Maybe<Order_State_Mutation_Response>;
  /** insert a single row into the table: "order_state" */
  insert_order_state_one?: Maybe<Order_State>;
  ping?: Maybe<PingOutput>;
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>;
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>;
  /** update multiples rows of table: "client" */
  update_client_many?: Maybe<Array<Maybe<Client_Mutation_Response>>>;
  /** update data of the table: "client_profile" */
  update_client_profile?: Maybe<Client_Profile_Mutation_Response>;
  /** update single row of the table: "client_profile" */
  update_client_profile_by_pk?: Maybe<Client_Profile>;
  /** update multiples rows of table: "client_profile" */
  update_client_profile_many?: Maybe<Array<Maybe<Client_Profile_Mutation_Response>>>;
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>;
  /** update single row of the table: "order" */
  update_order_by_pk?: Maybe<Order>;
  /** update data of the table: "order_collection" */
  update_order_collection?: Maybe<Order_Collection_Mutation_Response>;
  /** update single row of the table: "order_collection" */
  update_order_collection_by_pk?: Maybe<Order_Collection>;
  /** update multiples rows of table: "order_collection" */
  update_order_collection_many?: Maybe<Array<Maybe<Order_Collection_Mutation_Response>>>;
  /** update multiples rows of table: "order" */
  update_order_many?: Maybe<Array<Maybe<Order_Mutation_Response>>>;
  /** update data of the table: "order_test" */
  update_order_test?: Maybe<Order_Test_Mutation_Response>;
  /** update single row of the table: "order_test" */
  update_order_test_by_pk?: Maybe<Order_Test>;
  /** update multiples rows of table: "order_test" */
  update_order_test_many?: Maybe<Array<Maybe<Order_Test_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootClient_Complaint_SendArgs = {
  input: ClientComplaintSendInput;
};


/** mutation root */
export type Mutation_RootClient_Contact_SendArgs = {
  input: ClientContactSendInput;
};


/** mutation root */
export type Mutation_RootClient_Order_AddArgs = {
  order: OrderAddInput;
  paymentSdkVersion?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootClient_Verify_KdrArgs = {
  cardNumber: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Client_Favourite_TestArgs = {
  where: Client_Favourite_Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Favourite_Test_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Client_ProfileArgs = {
  where: Client_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Client_Favourite_TestArgs = {
  objects: Array<Client_Favourite_Test_Insert_Input>;
  on_conflict?: InputMaybe<Client_Favourite_Test_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Favourite_Test_OneArgs = {
  object: Client_Favourite_Test_Insert_Input;
  on_conflict?: InputMaybe<Client_Favourite_Test_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_ProfileArgs = {
  objects: Array<Client_Profile_Insert_Input>;
  on_conflict?: InputMaybe<Client_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Profile_OneArgs = {
  object: Client_Profile_Insert_Input;
  on_conflict?: InputMaybe<Client_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StateArgs = {
  objects: Array<Order_State_Insert_Input>;
  on_conflict?: InputMaybe<Order_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_State_OneArgs = {
  object: Order_State_Insert_Input;
  on_conflict?: InputMaybe<Order_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_ManyArgs = {
  updates: Array<Client_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Client_ProfileArgs = {
  _append?: InputMaybe<Client_Profile_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Profile_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Profile_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Profile_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Profile_Inc_Input>;
  _prepend?: InputMaybe<Client_Profile_Prepend_Input>;
  _set?: InputMaybe<Client_Profile_Set_Input>;
  where: Client_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Profile_By_PkArgs = {
  _append?: InputMaybe<Client_Profile_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Profile_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Profile_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Profile_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Profile_Inc_Input>;
  _prepend?: InputMaybe<Client_Profile_Prepend_Input>;
  _set?: InputMaybe<Client_Profile_Set_Input>;
  pk_columns: Client_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Profile_ManyArgs = {
  updates: Array<Client_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _set?: InputMaybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _set?: InputMaybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_CollectionArgs = {
  _set?: InputMaybe<Order_Collection_Set_Input>;
  where: Order_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Collection_By_PkArgs = {
  _set?: InputMaybe<Order_Collection_Set_Input>;
  pk_columns: Order_Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Collection_ManyArgs = {
  updates: Array<Order_Collection_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ManyArgs = {
  updates: Array<Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_TestArgs = {
  _append?: InputMaybe<Order_Test_Append_Input>;
  _delete_at_path?: InputMaybe<Order_Test_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Order_Test_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Order_Test_Delete_Key_Input>;
  _prepend?: InputMaybe<Order_Test_Prepend_Input>;
  _set?: InputMaybe<Order_Test_Set_Input>;
  where: Order_Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Test_By_PkArgs = {
  _append?: InputMaybe<Order_Test_Append_Input>;
  _delete_at_path?: InputMaybe<Order_Test_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Order_Test_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Order_Test_Delete_Key_Input>;
  _prepend?: InputMaybe<Order_Test_Prepend_Input>;
  _set?: InputMaybe<Order_Test_Set_Input>;
  pk_columns: Order_Test_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Test_ManyArgs = {
  updates: Array<Order_Test_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "nurse" */
export type Nurse = {
  __typename?: 'nurse';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  nurseMaterials: Array<Nurse_Material>;
  /** An array relationship */
  nurseTests: Array<Nurse_Test>;
  orderArea?: Maybe<Scalars['geography']>;
  phoneNumber: Scalars['String'];
};


/** columns and relationships of "nurse" */
export type NurseNurseMaterialsArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Material_Order_By>>;
  where?: InputMaybe<Nurse_Material_Bool_Exp>;
};


/** columns and relationships of "nurse" */
export type NurseNurseTestsArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Test_Order_By>>;
  where?: InputMaybe<Nurse_Test_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "nurse". All fields are combined with a logical 'AND'. */
export type Nurse_Bool_Exp = {
  _and?: InputMaybe<Array<Nurse_Bool_Exp>>;
  _not?: InputMaybe<Nurse_Bool_Exp>;
  _or?: InputMaybe<Array<Nurse_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nurseMaterials?: InputMaybe<Nurse_Material_Bool_Exp>;
  nurseTests?: InputMaybe<Nurse_Test_Bool_Exp>;
  orderArea?: InputMaybe<Geography_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "nurse_material" */
export type Nurse_Material = {
  __typename?: 'nurse_material';
  /** An object relationship */
  material: Material;
  materialId: Scalars['Int'];
  /** An object relationship */
  nurse: Nurse;
  nurseId: Scalars['Int'];
};

/** order by aggregate values of table "nurse_material" */
export type Nurse_Material_Aggregate_Order_By = {
  avg?: InputMaybe<Nurse_Material_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nurse_Material_Max_Order_By>;
  min?: InputMaybe<Nurse_Material_Min_Order_By>;
  stddev?: InputMaybe<Nurse_Material_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nurse_Material_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nurse_Material_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nurse_Material_Sum_Order_By>;
  var_pop?: InputMaybe<Nurse_Material_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nurse_Material_Var_Samp_Order_By>;
  variance?: InputMaybe<Nurse_Material_Variance_Order_By>;
};

/** order by avg() on columns of table "nurse_material" */
export type Nurse_Material_Avg_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nurse_material". All fields are combined with a logical 'AND'. */
export type Nurse_Material_Bool_Exp = {
  _and?: InputMaybe<Array<Nurse_Material_Bool_Exp>>;
  _not?: InputMaybe<Nurse_Material_Bool_Exp>;
  _or?: InputMaybe<Array<Nurse_Material_Bool_Exp>>;
  material?: InputMaybe<Material_Bool_Exp>;
  materialId?: InputMaybe<Int_Comparison_Exp>;
  nurse?: InputMaybe<Nurse_Bool_Exp>;
  nurseId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "nurse_material" */
export type Nurse_Material_Max_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "nurse_material" */
export type Nurse_Material_Min_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "nurse_material". */
export type Nurse_Material_Order_By = {
  material?: InputMaybe<Material_Order_By>;
  materialId?: InputMaybe<Order_By>;
  nurse?: InputMaybe<Nurse_Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** select columns of table "nurse_material" */
export enum Nurse_Material_Select_Column {
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  NurseId = 'nurseId'
}

/** order by stddev() on columns of table "nurse_material" */
export type Nurse_Material_Stddev_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "nurse_material" */
export type Nurse_Material_Stddev_Pop_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "nurse_material" */
export type Nurse_Material_Stddev_Samp_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nurse_material" */
export type Nurse_Material_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nurse_Material_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nurse_Material_Stream_Cursor_Value_Input = {
  materialId?: InputMaybe<Scalars['Int']>;
  nurseId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nurse_material" */
export type Nurse_Material_Sum_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "nurse_material" */
export type Nurse_Material_Var_Pop_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "nurse_material" */
export type Nurse_Material_Var_Samp_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "nurse_material" */
export type Nurse_Material_Variance_Order_By = {
  materialId?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "nurse". */
export type Nurse_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nurseMaterials_aggregate?: InputMaybe<Nurse_Material_Aggregate_Order_By>;
  nurseTests_aggregate?: InputMaybe<Nurse_Test_Aggregate_Order_By>;
  orderArea?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
};

/** select columns of table "nurse" */
export enum Nurse_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderArea = 'orderArea',
  /** column name */
  PhoneNumber = 'phoneNumber'
}

/** Streaming cursor of the table "nurse" */
export type Nurse_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nurse_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nurse_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderArea?: InputMaybe<Scalars['geography']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "nurse_test" */
export type Nurse_Test = {
  __typename?: 'nurse_test';
  /** An object relationship */
  nurse: Nurse;
  nurseId: Scalars['Int'];
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** order by aggregate values of table "nurse_test" */
export type Nurse_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Nurse_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nurse_Test_Max_Order_By>;
  min?: InputMaybe<Nurse_Test_Min_Order_By>;
  stddev?: InputMaybe<Nurse_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nurse_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nurse_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nurse_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Nurse_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nurse_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Nurse_Test_Variance_Order_By>;
};

/** order by avg() on columns of table "nurse_test" */
export type Nurse_Test_Avg_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nurse_test". All fields are combined with a logical 'AND'. */
export type Nurse_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Nurse_Test_Bool_Exp>>;
  _not?: InputMaybe<Nurse_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Nurse_Test_Bool_Exp>>;
  nurse?: InputMaybe<Nurse_Bool_Exp>;
  nurseId?: InputMaybe<Int_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "nurse_test" */
export type Nurse_Test_Max_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "nurse_test" */
export type Nurse_Test_Min_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "nurse_test". */
export type Nurse_Test_Order_By = {
  nurse?: InputMaybe<Nurse_Order_By>;
  nurseId?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "nurse_test" */
export enum Nurse_Test_Select_Column {
  /** column name */
  NurseId = 'nurseId',
  /** column name */
  TestId = 'testId'
}

/** order by stddev() on columns of table "nurse_test" */
export type Nurse_Test_Stddev_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "nurse_test" */
export type Nurse_Test_Stddev_Pop_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "nurse_test" */
export type Nurse_Test_Stddev_Samp_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nurse_test" */
export type Nurse_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nurse_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nurse_Test_Stream_Cursor_Value_Input = {
  nurseId?: InputMaybe<Scalars['Int']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nurse_test" */
export type Nurse_Test_Sum_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "nurse_test" */
export type Nurse_Test_Var_Pop_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "nurse_test" */
export type Nurse_Test_Var_Samp_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "nurse_test" */
export type Nurse_Test_Variance_Order_By = {
  nurseId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'order';
  /** An object relationship */
  client: Client;
  clientConsent1?: Maybe<Scalars['Boolean']>;
  clientConsent2?: Maybe<Scalars['Boolean']>;
  clientConsent3?: Maybe<Scalars['Boolean']>;
  clientId: Scalars['Int'];
  currentState?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_current_order_state_date" */
  currentStateDate?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_first_order_state_date" */
  firstStateDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  nurse?: Maybe<Nurse>;
  nurseId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  orderCollections: Array<Order_Collection>;
  /** An aggregate relationship */
  orderCollections_aggregate: Order_Collection_Aggregate;
  /** An array relationship */
  orderConsultations: Array<Order_Consultation>;
  /** An aggregate relationship */
  orderConsultations_aggregate: Order_Consultation_Aggregate;
  /** An array relationship */
  orderStates: Array<Order_State>;
  /** An array relationship */
  orderTests: Array<Order_Test>;
  /** An array relationship */
  orderTrainings: Array<Order_Training>;
  /** An aggregate relationship */
  orderTrainings_aggregate: Order_Training_Aggregate;
  paymentToken?: Maybe<Scalars['String']>;
  totalCost: Scalars['numeric'];
};


/** columns and relationships of "order" */
export type OrderOrderCollectionsArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderCollections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderConsultationsArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderConsultations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderStatesArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Order_By>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderTestsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  client?: InputMaybe<Client_Bool_Exp>;
  clientConsent1?: InputMaybe<Boolean_Comparison_Exp>;
  clientConsent2?: InputMaybe<Boolean_Comparison_Exp>;
  clientConsent3?: InputMaybe<Boolean_Comparison_Exp>;
  clientId?: InputMaybe<Int_Comparison_Exp>;
  currentState?: InputMaybe<String_Comparison_Exp>;
  currentStateDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstStateDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nurse?: InputMaybe<Nurse_Bool_Exp>;
  nurseId?: InputMaybe<Int_Comparison_Exp>;
  orderCollections?: InputMaybe<Order_Collection_Bool_Exp>;
  orderCollections_aggregate?: InputMaybe<Order_Collection_Aggregate_Bool_Exp>;
  orderConsultations?: InputMaybe<Order_Consultation_Bool_Exp>;
  orderConsultations_aggregate?: InputMaybe<Order_Consultation_Aggregate_Bool_Exp>;
  orderStates?: InputMaybe<Order_State_Bool_Exp>;
  orderTests?: InputMaybe<Order_Test_Bool_Exp>;
  orderTrainings?: InputMaybe<Order_Training_Bool_Exp>;
  orderTrainings_aggregate?: InputMaybe<Order_Training_Aggregate_Bool_Exp>;
  paymentToken?: InputMaybe<String_Comparison_Exp>;
  totalCost?: InputMaybe<Numeric_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_collection" */
export type Order_Collection = {
  __typename?: 'order_collection';
  address: Scalars['String'];
  city: Scalars['String'];
  clientNotes?: Maybe<Scalars['String']>;
  clientQuarantine?: Maybe<Scalars['Boolean']>;
  collectionCost: Scalars['numeric'];
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionDate: Scalars['timestamptz'];
  collectionKitCost: Scalars['numeric'];
  deliveryCost: Scalars['numeric'];
  id: Scalars['Int'];
  location: Scalars['geography'];
  /** An object relationship */
  nurse?: Maybe<Nurse>;
  nurseId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
};

/** aggregated selection of "order_collection" */
export type Order_Collection_Aggregate = {
  __typename?: 'order_collection_aggregate';
  aggregate?: Maybe<Order_Collection_Aggregate_Fields>;
  nodes: Array<Order_Collection>;
};

export type Order_Collection_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Order_Collection_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Order_Collection_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Order_Collection_Aggregate_Bool_Exp_Count>;
};

export type Order_Collection_Aggregate_Bool_Exp_Bool_And = {
  arguments: Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Collection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Collection_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Collection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Collection_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Collection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Collection_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_collection" */
export type Order_Collection_Aggregate_Fields = {
  __typename?: 'order_collection_aggregate_fields';
  avg?: Maybe<Order_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Collection_Max_Fields>;
  min?: Maybe<Order_Collection_Min_Fields>;
  stddev?: Maybe<Order_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Collection_Sum_Fields>;
  var_pop?: Maybe<Order_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Collection_Var_Samp_Fields>;
  variance?: Maybe<Order_Collection_Variance_Fields>;
};


/** aggregate fields of "order_collection" */
export type Order_Collection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Collection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_collection" */
export type Order_Collection_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Collection_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Collection_Max_Order_By>;
  min?: InputMaybe<Order_Collection_Min_Order_By>;
  stddev?: InputMaybe<Order_Collection_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Collection_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Collection_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Collection_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Collection_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Collection_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Collection_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Collection_Avg_Fields = {
  __typename?: 'order_collection_avg_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_collection" */
export type Order_Collection_Avg_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_collection". All fields are combined with a logical 'AND'. */
export type Order_Collection_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Collection_Bool_Exp>>;
  _not?: InputMaybe<Order_Collection_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Collection_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  clientNotes?: InputMaybe<String_Comparison_Exp>;
  clientQuarantine?: InputMaybe<Boolean_Comparison_Exp>;
  collectionCost?: InputMaybe<Numeric_Comparison_Exp>;
  collectionCostBeforeDiscount?: InputMaybe<Numeric_Comparison_Exp>;
  collectionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  collectionKitCost?: InputMaybe<Numeric_Comparison_Exp>;
  deliveryCost?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  nurse?: InputMaybe<Nurse_Bool_Exp>;
  nurseId?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Collection_Max_Fields = {
  __typename?: 'order_collection_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  collectionCost?: Maybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionDate?: Maybe<Scalars['timestamptz']>;
  collectionKitCost?: Maybe<Scalars['numeric']>;
  deliveryCost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  nurseId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_collection" */
export type Order_Collection_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientNotes?: InputMaybe<Order_By>;
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionDate?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Collection_Min_Fields = {
  __typename?: 'order_collection_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  collectionCost?: Maybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionDate?: Maybe<Scalars['timestamptz']>;
  collectionKitCost?: Maybe<Scalars['numeric']>;
  deliveryCost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  nurseId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_collection" */
export type Order_Collection_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientNotes?: InputMaybe<Order_By>;
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionDate?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_collection" */
export type Order_Collection_Mutation_Response = {
  __typename?: 'order_collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Collection>;
};

/** Ordering options when selecting data from "order_collection". */
export type Order_Collection_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientNotes?: InputMaybe<Order_By>;
  clientQuarantine?: InputMaybe<Order_By>;
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionDate?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  nurse?: InputMaybe<Nurse_Order_By>;
  nurseId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_collection */
export type Order_Collection_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_collection" */
export enum Order_Collection_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  ClientNotes = 'clientNotes',
  /** column name */
  ClientQuarantine = 'clientQuarantine',
  /** column name */
  CollectionCost = 'collectionCost',
  /** column name */
  CollectionCostBeforeDiscount = 'collectionCostBeforeDiscount',
  /** column name */
  CollectionDate = 'collectionDate',
  /** column name */
  CollectionKitCost = 'collectionKitCost',
  /** column name */
  DeliveryCost = 'deliveryCost',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  NurseId = 'nurseId',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostalCode = 'postalCode'
}

/** select "order_collection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order_collection" */
export enum Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ClientQuarantine = 'clientQuarantine'
}

/** select "order_collection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order_collection" */
export enum Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ClientQuarantine = 'clientQuarantine'
}

/** input type for updating data in table "order_collection" */
export type Order_Collection_Set_Input = {
  collectionDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Collection_Stddev_Fields = {
  __typename?: 'order_collection_stddev_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_collection" */
export type Order_Collection_Stddev_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Collection_Stddev_Pop_Fields = {
  __typename?: 'order_collection_stddev_pop_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_collection" */
export type Order_Collection_Stddev_Pop_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Collection_Stddev_Samp_Fields = {
  __typename?: 'order_collection_stddev_samp_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_collection" */
export type Order_Collection_Stddev_Samp_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_collection" */
export type Order_Collection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Collection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Collection_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientNotes?: InputMaybe<Scalars['String']>;
  clientQuarantine?: InputMaybe<Scalars['Boolean']>;
  collectionCost?: InputMaybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: InputMaybe<Scalars['numeric']>;
  collectionDate?: InputMaybe<Scalars['timestamptz']>;
  collectionKitCost?: InputMaybe<Scalars['numeric']>;
  deliveryCost?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['geography']>;
  nurseId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Order_Collection_Sum_Fields = {
  __typename?: 'order_collection_sum_fields';
  collectionCost?: Maybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionKitCost?: Maybe<Scalars['numeric']>;
  deliveryCost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  nurseId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_collection" */
export type Order_Collection_Sum_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

export type Order_Collection_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Collection_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Collection_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Collection_Var_Pop_Fields = {
  __typename?: 'order_collection_var_pop_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_collection" */
export type Order_Collection_Var_Pop_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Collection_Var_Samp_Fields = {
  __typename?: 'order_collection_var_samp_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_collection" */
export type Order_Collection_Var_Samp_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Collection_Variance_Fields = {
  __typename?: 'order_collection_variance_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_collection" */
export type Order_Collection_Variance_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** columns and relationships of "order_consultation" */
export type Order_Consultation = {
  __typename?: 'order_consultation';
  /** An object relationship */
  consultation: Consultation;
  consultationId: Scalars['Int'];
  cost: Scalars['numeric'];
  finishedOn?: Maybe<Scalars['timestamptz']>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  identity: Scalars['jsonb'];
  lastName: Scalars['String'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  preferredDate: Scalars['timestamptz'];
};


/** columns and relationships of "order_consultation" */
export type Order_ConsultationIdentityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "order_consultation" */
export type Order_Consultation_Aggregate = {
  __typename?: 'order_consultation_aggregate';
  aggregate?: Maybe<Order_Consultation_Aggregate_Fields>;
  nodes: Array<Order_Consultation>;
};

export type Order_Consultation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Consultation_Aggregate_Bool_Exp_Count>;
};

export type Order_Consultation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Consultation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_consultation" */
export type Order_Consultation_Aggregate_Fields = {
  __typename?: 'order_consultation_aggregate_fields';
  avg?: Maybe<Order_Consultation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Consultation_Max_Fields>;
  min?: Maybe<Order_Consultation_Min_Fields>;
  stddev?: Maybe<Order_Consultation_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Consultation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Consultation_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Consultation_Sum_Fields>;
  var_pop?: Maybe<Order_Consultation_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Consultation_Var_Samp_Fields>;
  variance?: Maybe<Order_Consultation_Variance_Fields>;
};


/** aggregate fields of "order_consultation" */
export type Order_Consultation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_consultation" */
export type Order_Consultation_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Consultation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Consultation_Max_Order_By>;
  min?: InputMaybe<Order_Consultation_Min_Order_By>;
  stddev?: InputMaybe<Order_Consultation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Consultation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Consultation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Consultation_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Consultation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Consultation_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Consultation_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Consultation_Avg_Fields = {
  __typename?: 'order_consultation_avg_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_consultation" */
export type Order_Consultation_Avg_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_consultation". All fields are combined with a logical 'AND'. */
export type Order_Consultation_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Consultation_Bool_Exp>>;
  _not?: InputMaybe<Order_Consultation_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Consultation_Bool_Exp>>;
  consultation?: InputMaybe<Consultation_Bool_Exp>;
  consultationId?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  finishedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity?: InputMaybe<Jsonb_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  preferredDate?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Consultation_Max_Fields = {
  __typename?: 'order_consultation_max_fields';
  consultationId?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  finishedOn?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredDate?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_consultation" */
export type Order_Consultation_Max_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  finishedOn?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  preferredDate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Consultation_Min_Fields = {
  __typename?: 'order_consultation_min_fields';
  consultationId?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  finishedOn?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredDate?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_consultation" */
export type Order_Consultation_Min_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  finishedOn?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  preferredDate?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_consultation". */
export type Order_Consultation_Order_By = {
  consultation?: InputMaybe<Consultation_Order_By>;
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  finishedOn?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  preferredDate?: InputMaybe<Order_By>;
};

/** select columns of table "order_consultation" */
export enum Order_Consultation_Select_Column {
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  Cost = 'cost',
  /** column name */
  FinishedOn = 'finishedOn',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PreferredDate = 'preferredDate'
}

/** aggregate stddev on columns */
export type Order_Consultation_Stddev_Fields = {
  __typename?: 'order_consultation_stddev_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_consultation" */
export type Order_Consultation_Stddev_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Consultation_Stddev_Pop_Fields = {
  __typename?: 'order_consultation_stddev_pop_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_consultation" */
export type Order_Consultation_Stddev_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Consultation_Stddev_Samp_Fields = {
  __typename?: 'order_consultation_stddev_samp_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_consultation" */
export type Order_Consultation_Stddev_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_consultation" */
export type Order_Consultation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Consultation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Consultation_Stream_Cursor_Value_Input = {
  consultationId?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  finishedOn?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Order_Consultation_Sum_Fields = {
  __typename?: 'order_consultation_sum_fields';
  consultationId?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_consultation" */
export type Order_Consultation_Sum_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Consultation_Var_Pop_Fields = {
  __typename?: 'order_consultation_var_pop_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_consultation" */
export type Order_Consultation_Var_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Consultation_Var_Samp_Fields = {
  __typename?: 'order_consultation_var_samp_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_consultation" */
export type Order_Consultation_Var_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Consultation_Variance_Fields = {
  __typename?: 'order_consultation_variance_fields';
  consultationId?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_consultation" */
export type Order_Consultation_Variance_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  __typename?: 'order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  client?: InputMaybe<Client_Order_By>;
  clientConsent1?: InputMaybe<Order_By>;
  clientConsent2?: InputMaybe<Order_By>;
  clientConsent3?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  currentState?: InputMaybe<Order_By>;
  currentStateDate?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  firstStateDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurse?: InputMaybe<Nurse_Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderCollections_aggregate?: InputMaybe<Order_Collection_Aggregate_Order_By>;
  orderConsultations_aggregate?: InputMaybe<Order_Consultation_Aggregate_Order_By>;
  orderStates_aggregate?: InputMaybe<Order_State_Aggregate_Order_By>;
  orderTests_aggregate?: InputMaybe<Order_Test_Aggregate_Order_By>;
  orderTrainings_aggregate?: InputMaybe<Order_Training_Aggregate_Order_By>;
  paymentToken?: InputMaybe<Order_By>;
  totalCost?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  ClientConsent1 = 'clientConsent1',
  /** column name */
  ClientConsent2 = 'clientConsent2',
  /** column name */
  ClientConsent3 = 'clientConsent3',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  NurseId = 'nurseId',
  /** column name */
  PaymentToken = 'paymentToken',
  /** column name */
  TotalCost = 'totalCost'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  clientConsent1?: InputMaybe<Scalars['Boolean']>;
  clientConsent2?: InputMaybe<Scalars['Boolean']>;
  clientConsent3?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "order_state" */
export type Order_State = {
  __typename?: 'order_state';
  date: Scalars['timestamptz'];
  extra?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  state: Order_State_Enum_Enum;
};


/** columns and relationships of "order_state" */
export type Order_StateExtraArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "order_state" */
export type Order_State_Aggregate_Order_By = {
  avg?: InputMaybe<Order_State_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_State_Max_Order_By>;
  min?: InputMaybe<Order_State_Min_Order_By>;
  stddev?: InputMaybe<Order_State_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_State_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_State_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_State_Sum_Order_By>;
  var_pop?: InputMaybe<Order_State_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_State_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_State_Variance_Order_By>;
};

/** order by avg() on columns of table "order_state" */
export type Order_State_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_state". All fields are combined with a logical 'AND'. */
export type Order_State_Bool_Exp = {
  _and?: InputMaybe<Array<Order_State_Bool_Exp>>;
  _not?: InputMaybe<Order_State_Bool_Exp>;
  _or?: InputMaybe<Array<Order_State_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  extra?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<Order_State_Enum_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_state" */
export enum Order_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderStatePkey = 'order_state_pkey'
}

/** columns and relationships of "order_state_enum" */
export type Order_State_Enum = {
  __typename?: 'order_state_enum';
  state: Scalars['String'];
};

/** Boolean expression to filter rows from the table "order_state_enum". All fields are combined with a logical 'AND'. */
export type Order_State_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<Order_State_Enum_Bool_Exp>>;
  _not?: InputMaybe<Order_State_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<Order_State_Enum_Bool_Exp>>;
  state?: InputMaybe<String_Comparison_Exp>;
};

export enum Order_State_Enum_Enum {
  AwaitingClientConfirmation = 'AwaitingClientConfirmation',
  AwaitingConfirmation = 'AwaitingConfirmation',
  Cancelled = 'Cancelled',
  CollectingSamples = 'CollectingSamples',
  Confirmed = 'Confirmed',
  DifferentDateAccepted = 'DifferentDateAccepted',
  DifferentDateProposed = 'DifferentDateProposed',
  DifferentDateRejected = 'DifferentDateRejected',
  ExaminingSamples = 'ExaminingSamples',
  Finished = 'Finished',
  OnTheWayToClient = 'OnTheWayToClient',
  OnTheWayToLab = 'OnTheWayToLab',
  Unpaid = 'Unpaid'
}

/** Boolean expression to compare columns of type "order_state_enum_enum". All fields are combined with logical 'AND'. */
export type Order_State_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Order_State_Enum_Enum>;
  _in?: InputMaybe<Array<Order_State_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Order_State_Enum_Enum>;
  _nin?: InputMaybe<Array<Order_State_Enum_Enum>>;
};

/** Ordering options when selecting data from "order_state_enum". */
export type Order_State_Enum_Order_By = {
  state?: InputMaybe<Order_By>;
};

/** select columns of table "order_state_enum" */
export enum Order_State_Enum_Select_Column {
  /** column name */
  State = 'state'
}

/** Streaming cursor of the table "order_state_enum" */
export type Order_State_Enum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_State_Enum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_State_Enum_Stream_Cursor_Value_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "order_state" */
export type Order_State_Insert_Input = {
  extra?: InputMaybe<Scalars['jsonb']>;
  orderId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Order_State_Enum_Enum>;
};

/** order by max() on columns of table "order_state" */
export type Order_State_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_state" */
export type Order_State_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_state" */
export type Order_State_Mutation_Response = {
  __typename?: 'order_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_State>;
};

/** on_conflict condition type for table "order_state" */
export type Order_State_On_Conflict = {
  constraint: Order_State_Constraint;
  update_columns?: Array<Order_State_Update_Column>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};

/** Ordering options when selecting data from "order_state". */
export type Order_State_Order_By = {
  date?: InputMaybe<Order_By>;
  extra?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** select columns of table "order_state" */
export enum Order_State_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Extra = 'extra',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  State = 'state'
}

/** order by stddev() on columns of table "order_state" */
export type Order_State_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_state" */
export type Order_State_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_state" */
export type Order_State_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_state" */
export type Order_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_State_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  extra?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Order_State_Enum_Enum>;
};

/** order by sum() on columns of table "order_state" */
export type Order_State_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "order_state" (current role has no relevant permissions) */
export enum Order_State_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "order_state" */
export type Order_State_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_state" */
export type Order_State_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_state" */
export type Order_State_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  clientConsent1?: InputMaybe<Scalars['Boolean']>;
  clientConsent2?: InputMaybe<Scalars['Boolean']>;
  clientConsent3?: InputMaybe<Scalars['Boolean']>;
  clientId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  nurseId?: InputMaybe<Scalars['Int']>;
  paymentToken?: InputMaybe<Scalars['String']>;
  totalCost?: InputMaybe<Scalars['numeric']>;
};

/** columns and relationships of "order_test" */
export type Order_Test = {
  __typename?: 'order_test';
  collectionPointId?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  identity?: Maybe<Scalars['jsonb']>;
  /** A computed field, executes function "order_test_laboratory" */
  laboratory?: Maybe<Array<Laboratory>>;
  lastName: Scalars['String'];
  /** A computed field, executes function "order_test_name" */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  /** A computed field, executes function "order_test_pesel" */
  pesel?: Maybe<Scalars['String']>;
  retrievedOn?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
  /** An array relationship */
  testResults: Array<Order_Test_Result>;
};


/** columns and relationships of "order_test" */
export type Order_TestIdentityArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "order_test" */
export type Order_TestLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


/** columns and relationships of "order_test" */
export type Order_TestTestResultsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};

/** order by aggregate values of table "order_test" */
export type Order_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Test_Max_Order_By>;
  min?: InputMaybe<Order_Test_Min_Order_By>;
  stddev?: InputMaybe<Order_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Test_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Test_Append_Input = {
  identity?: InputMaybe<Scalars['jsonb']>;
};

/** order by avg() on columns of table "order_test" */
export type Order_Test_Avg_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_test". All fields are combined with a logical 'AND'. */
export type Order_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Test_Bool_Exp>>;
  _not?: InputMaybe<Order_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Test_Bool_Exp>>;
  collectionPointId?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity?: InputMaybe<Jsonb_Comparison_Exp>;
  laboratory?: InputMaybe<Laboratory_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  pesel?: InputMaybe<String_Comparison_Exp>;
  retrievedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
  testResults?: InputMaybe<Order_Test_Result_Bool_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Test_Delete_At_Path_Input = {
  identity?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Test_Delete_Elem_Input = {
  identity?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Test_Delete_Key_Input = {
  identity?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "order_test" */
export type Order_Test_Max_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  retrievedOn?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_test" */
export type Order_Test_Min_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  retrievedOn?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_test" */
export type Order_Test_Mutation_Response = {
  __typename?: 'order_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Test>;
};

/** Ordering options when selecting data from "order_test". */
export type Order_Test_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  laboratory_aggregate?: InputMaybe<Laboratory_Aggregate_Order_By>;
  lastName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  pesel?: InputMaybe<Order_By>;
  retrievedOn?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
  testResults_aggregate?: InputMaybe<Order_Test_Result_Aggregate_Order_By>;
};

/** primary key columns input for table: order_test */
export type Order_Test_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Test_Prepend_Input = {
  identity?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "order_test_result" */
export type Order_Test_Result = {
  __typename?: 'order_test_result';
  criticalParameter: Scalars['Boolean'];
  date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  file: File;
  fileId: Scalars['Int'];
  /** An object relationship */
  orderTest: Order_Test;
  orderTestId: Scalars['Int'];
};

/** order by aggregate values of table "order_test_result" */
export type Order_Test_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Test_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Test_Result_Max_Order_By>;
  min?: InputMaybe<Order_Test_Result_Min_Order_By>;
  stddev?: InputMaybe<Order_Test_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Test_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Test_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Test_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Test_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Test_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Test_Result_Variance_Order_By>;
};

/** order by avg() on columns of table "order_test_result" */
export type Order_Test_Result_Avg_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_test_result". All fields are combined with a logical 'AND'. */
export type Order_Test_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Test_Result_Bool_Exp>>;
  _not?: InputMaybe<Order_Test_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Test_Result_Bool_Exp>>;
  criticalParameter?: InputMaybe<Boolean_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  fileId?: InputMaybe<Int_Comparison_Exp>;
  orderTest?: InputMaybe<Order_Test_Bool_Exp>;
  orderTestId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "order_test_result" */
export type Order_Test_Result_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_test_result" */
export type Order_Test_Result_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_test_result". */
export type Order_Test_Result_Order_By = {
  criticalParameter?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  file?: InputMaybe<File_Order_By>;
  fileId?: InputMaybe<Order_By>;
  orderTest?: InputMaybe<Order_Test_Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** select columns of table "order_test_result" */
export enum Order_Test_Result_Select_Column {
  /** column name */
  CriticalParameter = 'criticalParameter',
  /** column name */
  Date = 'date',
  /** column name */
  FileId = 'fileId',
  /** column name */
  OrderTestId = 'orderTestId'
}

/** order by stddev() on columns of table "order_test_result" */
export type Order_Test_Result_Stddev_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_test_result" */
export type Order_Test_Result_Stddev_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_test_result" */
export type Order_Test_Result_Stddev_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_test_result" */
export type Order_Test_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Test_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Test_Result_Stream_Cursor_Value_Input = {
  criticalParameter?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['Int']>;
  orderTestId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_test_result" */
export type Order_Test_Result_Sum_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "order_test_result" */
export type Order_Test_Result_Var_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_test_result" */
export type Order_Test_Result_Var_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_test_result" */
export type Order_Test_Result_Variance_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** select columns of table "order_test" */
export enum Order_Test_Select_Column {
  /** column name */
  CollectionPointId = 'collectionPointId',
  /** column name */
  Cost = 'cost',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  RetrievedOn = 'retrievedOn',
  /** column name */
  TestId = 'testId'
}

/** input type for updating data in table "order_test" */
export type Order_Test_Set_Input = {
  identity?: InputMaybe<Scalars['jsonb']>;
};

/** order by stddev() on columns of table "order_test" */
export type Order_Test_Stddev_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_test" */
export type Order_Test_Stddev_Pop_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_test" */
export type Order_Test_Stddev_Samp_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_test" */
export type Order_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Test_Stream_Cursor_Value_Input = {
  collectionPointId?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  retrievedOn?: InputMaybe<Scalars['timestamptz']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_test" */
export type Order_Test_Sum_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

export type Order_Test_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Test_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Test_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Test_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Test_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Test_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Test_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Test_Bool_Exp;
};

/** order by var_pop() on columns of table "order_test" */
export type Order_Test_Var_Pop_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_test" */
export type Order_Test_Var_Samp_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_test" */
export type Order_Test_Variance_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** columns and relationships of "order_training" */
export type Order_Training = {
  __typename?: 'order_training';
  cost: Scalars['numeric'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  /** An object relationship */
  training: Training;
  trainingId: Scalars['Int'];
};

/** aggregated selection of "order_training" */
export type Order_Training_Aggregate = {
  __typename?: 'order_training_aggregate';
  aggregate?: Maybe<Order_Training_Aggregate_Fields>;
  nodes: Array<Order_Training>;
};

export type Order_Training_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Training_Aggregate_Bool_Exp_Count>;
};

export type Order_Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_training" */
export type Order_Training_Aggregate_Fields = {
  __typename?: 'order_training_aggregate_fields';
  avg?: Maybe<Order_Training_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Training_Max_Fields>;
  min?: Maybe<Order_Training_Min_Fields>;
  stddev?: Maybe<Order_Training_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Training_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Training_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Training_Sum_Fields>;
  var_pop?: Maybe<Order_Training_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Training_Var_Samp_Fields>;
  variance?: Maybe<Order_Training_Variance_Fields>;
};


/** aggregate fields of "order_training" */
export type Order_Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_training" */
export type Order_Training_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Training_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Training_Max_Order_By>;
  min?: InputMaybe<Order_Training_Min_Order_By>;
  stddev?: InputMaybe<Order_Training_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Training_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Training_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Training_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Training_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Training_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Training_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Training_Avg_Fields = {
  __typename?: 'order_training_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_training" */
export type Order_Training_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_training". All fields are combined with a logical 'AND'. */
export type Order_Training_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Training_Bool_Exp>>;
  _not?: InputMaybe<Order_Training_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Training_Bool_Exp>>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  training?: InputMaybe<Training_Bool_Exp>;
  trainingId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Training_Max_Fields = {
  __typename?: 'order_training_max_fields';
  cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  trainingId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_training" */
export type Order_Training_Max_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Training_Min_Fields = {
  __typename?: 'order_training_min_fields';
  cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  trainingId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "order_training" */
export type Order_Training_Min_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_training". */
export type Order_Training_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  training?: InputMaybe<Training_Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** select columns of table "order_training" */
export enum Order_Training_Select_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  TrainingId = 'trainingId'
}

/** aggregate stddev on columns */
export type Order_Training_Stddev_Fields = {
  __typename?: 'order_training_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_training" */
export type Order_Training_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Training_Stddev_Pop_Fields = {
  __typename?: 'order_training_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_training" */
export type Order_Training_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Training_Stddev_Samp_Fields = {
  __typename?: 'order_training_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_training" */
export type Order_Training_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_training" */
export type Order_Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Training_Stream_Cursor_Value_Input = {
  cost?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  trainingId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Order_Training_Sum_Fields = {
  __typename?: 'order_training_sum_fields';
  cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  trainingId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_training" */
export type Order_Training_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Training_Var_Pop_Fields = {
  __typename?: 'order_training_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_training" */
export type Order_Training_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Training_Var_Samp_Fields = {
  __typename?: 'order_training_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_training" */
export type Order_Training_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Training_Variance_Fields = {
  __typename?: 'order_training_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  trainingId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_training" */
export type Order_Training_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  trainingId?: InputMaybe<Order_By>;
};

export type Order_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "available_laboratory" which returns "laboratory" */
  available_laboratory: Array<Laboratory>;
  /** execute function "available_laboratory" and query aggregates on result of table type "laboratory" */
  available_laboratory_aggregate: Laboratory_Aggregate;
  /** execute function "available_test_tag" which returns "test_tag" */
  available_test_tag: Array<Test_Tag>;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "client_favourite_test" */
  client_favourite_test: Array<Client_Favourite_Test>;
  /** fetch aggregated fields from the table: "client_favourite_test" */
  client_favourite_test_aggregate: Client_Favourite_Test_Aggregate;
  /** fetch data from the table: "client_favourite_test" using primary key columns */
  client_favourite_test_by_pk?: Maybe<Client_Favourite_Test>;
  client_order_cost: OrderCostOutput;
  /** fetch data from the table: "client_profile" */
  client_profile: Array<Client_Profile>;
  /** fetch data from the table: "client_profile" using primary key columns */
  client_profile_by_pk?: Maybe<Client_Profile>;
  /** fetch data from the table: "consultation" */
  consultation: Array<Consultation>;
  /** fetch aggregated fields from the table: "consultation" */
  consultation_aggregate: Consultation_Aggregate;
  /** fetch data from the table: "consultation" using primary key columns */
  consultation_by_pk?: Maybe<Consultation>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "laboratory" */
  laboratory: Array<Laboratory>;
  /** fetch aggregated fields from the table: "laboratory" */
  laboratory_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "laboratory" using primary key columns */
  laboratory_by_pk?: Maybe<Laboratory>;
  /** fetch data from the table: "laboratory_test" */
  laboratory_test: Array<Laboratory_Test>;
  /** fetch aggregated fields from the table: "laboratory_test" */
  laboratory_test_aggregate: Laboratory_Test_Aggregate;
  /** fetch data from the table: "laboratory_test" using primary key columns */
  laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** fetch data from the table: "material" */
  material: Array<Material>;
  /** fetch data from the table: "material" using primary key columns */
  material_by_pk?: Maybe<Material>;
  /** execute function "nearby_unconfirmed_order" which returns "order" */
  nearby_unconfirmed_order: Array<Order>;
  /** fetch data from the table: "nurse" */
  nurse: Array<Nurse>;
  /** fetch data from the table: "nurse" using primary key columns */
  nurse_by_pk?: Maybe<Nurse>;
  /** fetch data from the table: "nurse_material" */
  nurse_material: Array<Nurse_Material>;
  /** fetch data from the table: "nurse_material" using primary key columns */
  nurse_material_by_pk?: Maybe<Nurse_Material>;
  /** fetch data from the table: "nurse_test" */
  nurse_test: Array<Nurse_Test>;
  /** fetch data from the table: "nurse_test" using primary key columns */
  nurse_test_by_pk?: Maybe<Nurse_Test>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_collection" */
  order_collection: Array<Order_Collection>;
  /** fetch aggregated fields from the table: "order_collection" */
  order_collection_aggregate: Order_Collection_Aggregate;
  /** fetch data from the table: "order_collection" using primary key columns */
  order_collection_by_pk?: Maybe<Order_Collection>;
  /** fetch data from the table: "order_consultation" */
  order_consultation: Array<Order_Consultation>;
  /** fetch aggregated fields from the table: "order_consultation" */
  order_consultation_aggregate: Order_Consultation_Aggregate;
  /** fetch data from the table: "order_consultation" using primary key columns */
  order_consultation_by_pk?: Maybe<Order_Consultation>;
  /** fetch data from the table: "order_state" */
  order_state: Array<Order_State>;
  /** fetch data from the table: "order_state" using primary key columns */
  order_state_by_pk?: Maybe<Order_State>;
  /** fetch data from the table: "order_state_enum" */
  order_state_enum: Array<Order_State_Enum>;
  /** fetch data from the table: "order_state_enum" using primary key columns */
  order_state_enum_by_pk?: Maybe<Order_State_Enum>;
  /** fetch data from the table: "order_test" */
  order_test: Array<Order_Test>;
  /** fetch data from the table: "order_test" using primary key columns */
  order_test_by_pk?: Maybe<Order_Test>;
  /** fetch data from the table: "order_test_result" */
  order_test_result: Array<Order_Test_Result>;
  /** fetch data from the table: "order_test_result" using primary key columns */
  order_test_result_by_pk?: Maybe<Order_Test_Result>;
  /** fetch data from the table: "order_training" */
  order_training: Array<Order_Training>;
  /** fetch aggregated fields from the table: "order_training" */
  order_training_aggregate: Order_Training_Aggregate;
  /** fetch data from the table: "order_training" using primary key columns */
  order_training_by_pk?: Maybe<Order_Training>;
  /** execute function "search_test" which returns "test" */
  search_test: Array<Test>;
  /** execute function "search_test" and query aggregates on result of table type "test" */
  search_test_aggregate: Test_Aggregate;
  /** execute function "search_test_most_popular" which returns "test" */
  search_test_most_popular: Array<Test>;
  /** execute function "search_test_most_popular" and query aggregates on result of table type "test" */
  search_test_most_popular_aggregate: Test_Aggregate;
  /** execute function "search_test_tag" which returns "test" */
  search_test_tag: Array<Test>;
  /** execute function "search_test_tag" and query aggregates on result of table type "test" */
  search_test_tag_aggregate: Test_Aggregate;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch aggregated fields from the table: "test" */
  test_aggregate: Test_Aggregate;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** execute function "test_laboratories" which returns "laboratory" */
  test_laboratories: Array<Laboratory>;
  /** execute function "test_laboratories" and query aggregates on result of table type "laboratory" */
  test_laboratories_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "test_material" */
  test_material: Array<Test_Material>;
  /** fetch data from the table: "test_material" using primary key columns */
  test_material_by_pk?: Maybe<Test_Material>;
  /** fetch data from the table: "test_tag" */
  test_tag: Array<Test_Tag>;
  /** fetch data from the table: "test_tag" using primary key columns */
  test_tag_by_pk?: Maybe<Test_Tag>;
  /** execute function "tests_by_collection_point_and_nurse" which returns "test" */
  tests_by_collection_point_and_nurse: Array<Test>;
  /** execute function "tests_by_collection_point_and_nurse" and query aggregates on result of table type "test" */
  tests_by_collection_point_and_nurse_aggregate: Test_Aggregate;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
};


export type Query_RootAvailable_LaboratoryArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootAvailable_Laboratory_AggregateArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootAvailable_Test_TagArgs = {
  args: Available_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Query_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Query_RootClient_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClient_Favourite_TestArgs = {
  distinct_on?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Favourite_Test_Order_By>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


export type Query_RootClient_Favourite_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Favourite_Test_Order_By>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


export type Query_RootClient_Favourite_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClient_Order_CostArgs = {
  order: OrderCostInput;
};


export type Query_RootClient_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Client_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Profile_Order_By>>;
  where?: InputMaybe<Client_Profile_Bool_Exp>;
};


export type Query_RootClient_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootConsultationArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Query_RootConsultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Query_RootConsultation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootLaboratory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootLaboratory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratory_TestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Query_RootLaboratory_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Query_RootLaboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMaterialArgs = {
  distinct_on?: InputMaybe<Array<Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Material_Order_By>>;
  where?: InputMaybe<Material_Bool_Exp>;
};


export type Query_RootMaterial_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNearby_Unconfirmed_OrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootNurseArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Order_By>>;
  where?: InputMaybe<Nurse_Bool_Exp>;
};


export type Query_RootNurse_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNurse_MaterialArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Material_Order_By>>;
  where?: InputMaybe<Nurse_Material_Bool_Exp>;
};


export type Query_RootNurse_Material_By_PkArgs = {
  materialId: Scalars['Int'];
  nurseId: Scalars['Int'];
};


export type Query_RootNurse_TestArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Test_Order_By>>;
  where?: InputMaybe<Nurse_Test_Bool_Exp>;
};


export type Query_RootNurse_Test_By_PkArgs = {
  nurseId: Scalars['Int'];
  testId: Scalars['Int'];
};


export type Query_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_CollectionArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Query_RootOrder_Collection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Query_RootOrder_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_ConsultationArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


export type Query_RootOrder_Consultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


export type Query_RootOrder_Consultation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_StateArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Order_By>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


export type Query_RootOrder_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_State_EnumArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Enum_Order_By>>;
  where?: InputMaybe<Order_State_Enum_Bool_Exp>;
};


export type Query_RootOrder_State_Enum_By_PkArgs = {
  state: Scalars['String'];
};


export type Query_RootOrder_TestArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


export type Query_RootOrder_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Test_ResultArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


export type Query_RootOrder_Test_Result_By_PkArgs = {
  fileId: Scalars['Int'];
  orderTestId: Scalars['Int'];
};


export type Query_RootOrder_TrainingArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


export type Query_RootOrder_Training_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


export type Query_RootOrder_Training_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSearch_TestArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_AggregateArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_Most_PopularArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_Most_Popular_AggregateArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_TagArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_Tag_AggregateArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTest_LaboratoriesArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootTest_Laboratories_AggregateArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootTest_MaterialArgs = {
  distinct_on?: InputMaybe<Array<Test_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Material_Order_By>>;
  where?: InputMaybe<Test_Material_Bool_Exp>;
};


export type Query_RootTest_Material_By_PkArgs = {
  materialId: Scalars['Int'];
  testId: Scalars['Int'];
};


export type Query_RootTest_TagArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Query_RootTest_Tag_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTests_By_Collection_Point_And_NurseArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTests_By_Collection_Point_And_Nurse_AggregateArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Query_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Query_RootTraining_By_PkArgs = {
  id: Scalars['Int'];
};

export type Search_Test_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
  query?: InputMaybe<Scalars['String']>;
};

export type Search_Test_Most_Popular_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type Search_Test_Tag_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  _tag?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "available_laboratory" which returns "laboratory" */
  available_laboratory: Array<Laboratory>;
  /** execute function "available_laboratory" and query aggregates on result of table type "laboratory" */
  available_laboratory_aggregate: Laboratory_Aggregate;
  /** execute function "available_test_tag" which returns "test_tag" */
  available_test_tag: Array<Test_Tag>;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "client_favourite_test" */
  client_favourite_test: Array<Client_Favourite_Test>;
  /** fetch aggregated fields from the table: "client_favourite_test" */
  client_favourite_test_aggregate: Client_Favourite_Test_Aggregate;
  /** fetch data from the table: "client_favourite_test" using primary key columns */
  client_favourite_test_by_pk?: Maybe<Client_Favourite_Test>;
  /** fetch data from the table in a streaming manner: "client_favourite_test" */
  client_favourite_test_stream: Array<Client_Favourite_Test>;
  /** fetch data from the table: "client_profile" */
  client_profile: Array<Client_Profile>;
  /** fetch data from the table: "client_profile" using primary key columns */
  client_profile_by_pk?: Maybe<Client_Profile>;
  /** fetch data from the table in a streaming manner: "client_profile" */
  client_profile_stream: Array<Client_Profile>;
  /** fetch data from the table in a streaming manner: "client" */
  client_stream: Array<Client>;
  /** fetch data from the table: "consultation" */
  consultation: Array<Consultation>;
  /** fetch aggregated fields from the table: "consultation" */
  consultation_aggregate: Consultation_Aggregate;
  /** fetch data from the table: "consultation" using primary key columns */
  consultation_by_pk?: Maybe<Consultation>;
  /** fetch data from the table in a streaming manner: "consultation" */
  consultation_stream: Array<Consultation>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>;
  /** fetch data from the table: "laboratory" */
  laboratory: Array<Laboratory>;
  /** fetch aggregated fields from the table: "laboratory" */
  laboratory_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "laboratory" using primary key columns */
  laboratory_by_pk?: Maybe<Laboratory>;
  /** fetch data from the table in a streaming manner: "laboratory" */
  laboratory_stream: Array<Laboratory>;
  /** fetch data from the table: "laboratory_test" */
  laboratory_test: Array<Laboratory_Test>;
  /** fetch aggregated fields from the table: "laboratory_test" */
  laboratory_test_aggregate: Laboratory_Test_Aggregate;
  /** fetch data from the table: "laboratory_test" using primary key columns */
  laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** fetch data from the table in a streaming manner: "laboratory_test" */
  laboratory_test_stream: Array<Laboratory_Test>;
  /** fetch data from the table: "material" */
  material: Array<Material>;
  /** fetch data from the table: "material" using primary key columns */
  material_by_pk?: Maybe<Material>;
  /** fetch data from the table in a streaming manner: "material" */
  material_stream: Array<Material>;
  /** execute function "nearby_unconfirmed_order" which returns "order" */
  nearby_unconfirmed_order: Array<Order>;
  /** fetch data from the table: "nurse" */
  nurse: Array<Nurse>;
  /** fetch data from the table: "nurse" using primary key columns */
  nurse_by_pk?: Maybe<Nurse>;
  /** fetch data from the table: "nurse_material" */
  nurse_material: Array<Nurse_Material>;
  /** fetch data from the table: "nurse_material" using primary key columns */
  nurse_material_by_pk?: Maybe<Nurse_Material>;
  /** fetch data from the table in a streaming manner: "nurse_material" */
  nurse_material_stream: Array<Nurse_Material>;
  /** fetch data from the table in a streaming manner: "nurse" */
  nurse_stream: Array<Nurse>;
  /** fetch data from the table: "nurse_test" */
  nurse_test: Array<Nurse_Test>;
  /** fetch data from the table: "nurse_test" using primary key columns */
  nurse_test_by_pk?: Maybe<Nurse_Test>;
  /** fetch data from the table in a streaming manner: "nurse_test" */
  nurse_test_stream: Array<Nurse_Test>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_collection" */
  order_collection: Array<Order_Collection>;
  /** fetch aggregated fields from the table: "order_collection" */
  order_collection_aggregate: Order_Collection_Aggregate;
  /** fetch data from the table: "order_collection" using primary key columns */
  order_collection_by_pk?: Maybe<Order_Collection>;
  /** fetch data from the table in a streaming manner: "order_collection" */
  order_collection_stream: Array<Order_Collection>;
  /** fetch data from the table: "order_consultation" */
  order_consultation: Array<Order_Consultation>;
  /** fetch aggregated fields from the table: "order_consultation" */
  order_consultation_aggregate: Order_Consultation_Aggregate;
  /** fetch data from the table: "order_consultation" using primary key columns */
  order_consultation_by_pk?: Maybe<Order_Consultation>;
  /** fetch data from the table in a streaming manner: "order_consultation" */
  order_consultation_stream: Array<Order_Consultation>;
  /** fetch data from the table: "order_state" */
  order_state: Array<Order_State>;
  /** fetch data from the table: "order_state" using primary key columns */
  order_state_by_pk?: Maybe<Order_State>;
  /** fetch data from the table: "order_state_enum" */
  order_state_enum: Array<Order_State_Enum>;
  /** fetch data from the table: "order_state_enum" using primary key columns */
  order_state_enum_by_pk?: Maybe<Order_State_Enum>;
  /** fetch data from the table in a streaming manner: "order_state_enum" */
  order_state_enum_stream: Array<Order_State_Enum>;
  /** fetch data from the table in a streaming manner: "order_state" */
  order_state_stream: Array<Order_State>;
  /** fetch data from the table in a streaming manner: "order" */
  order_stream: Array<Order>;
  /** fetch data from the table: "order_test" */
  order_test: Array<Order_Test>;
  /** fetch data from the table: "order_test" using primary key columns */
  order_test_by_pk?: Maybe<Order_Test>;
  /** fetch data from the table: "order_test_result" */
  order_test_result: Array<Order_Test_Result>;
  /** fetch data from the table: "order_test_result" using primary key columns */
  order_test_result_by_pk?: Maybe<Order_Test_Result>;
  /** fetch data from the table in a streaming manner: "order_test_result" */
  order_test_result_stream: Array<Order_Test_Result>;
  /** fetch data from the table in a streaming manner: "order_test" */
  order_test_stream: Array<Order_Test>;
  /** fetch data from the table: "order_training" */
  order_training: Array<Order_Training>;
  /** fetch aggregated fields from the table: "order_training" */
  order_training_aggregate: Order_Training_Aggregate;
  /** fetch data from the table: "order_training" using primary key columns */
  order_training_by_pk?: Maybe<Order_Training>;
  /** fetch data from the table in a streaming manner: "order_training" */
  order_training_stream: Array<Order_Training>;
  /** execute function "search_test" which returns "test" */
  search_test: Array<Test>;
  /** execute function "search_test" and query aggregates on result of table type "test" */
  search_test_aggregate: Test_Aggregate;
  /** execute function "search_test_most_popular" which returns "test" */
  search_test_most_popular: Array<Test>;
  /** execute function "search_test_most_popular" and query aggregates on result of table type "test" */
  search_test_most_popular_aggregate: Test_Aggregate;
  /** execute function "search_test_tag" which returns "test" */
  search_test_tag: Array<Test>;
  /** execute function "search_test_tag" and query aggregates on result of table type "test" */
  search_test_tag_aggregate: Test_Aggregate;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table in a streaming manner: "tag" */
  tag_stream: Array<Tag>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch aggregated fields from the table: "test" */
  test_aggregate: Test_Aggregate;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** execute function "test_laboratories" which returns "laboratory" */
  test_laboratories: Array<Laboratory>;
  /** execute function "test_laboratories" and query aggregates on result of table type "laboratory" */
  test_laboratories_aggregate: Laboratory_Aggregate;
  /** fetch data from the table: "test_material" */
  test_material: Array<Test_Material>;
  /** fetch data from the table: "test_material" using primary key columns */
  test_material_by_pk?: Maybe<Test_Material>;
  /** fetch data from the table in a streaming manner: "test_material" */
  test_material_stream: Array<Test_Material>;
  /** fetch data from the table in a streaming manner: "test" */
  test_stream: Array<Test>;
  /** fetch data from the table: "test_tag" */
  test_tag: Array<Test_Tag>;
  /** fetch data from the table: "test_tag" using primary key columns */
  test_tag_by_pk?: Maybe<Test_Tag>;
  /** fetch data from the table in a streaming manner: "test_tag" */
  test_tag_stream: Array<Test_Tag>;
  /** execute function "tests_by_collection_point_and_nurse" which returns "test" */
  tests_by_collection_point_and_nurse: Array<Test>;
  /** execute function "tests_by_collection_point_and_nurse" and query aggregates on result of table type "test" */
  tests_by_collection_point_and_nurse_aggregate: Test_Aggregate;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
  /** fetch data from the table in a streaming manner: "training" */
  training_stream: Array<Training>;
};


export type Subscription_RootAvailable_LaboratoryArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootAvailable_Laboratory_AggregateArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootAvailable_Test_TagArgs = {
  args: Available_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Subscription_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootClient_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClient_Favourite_TestArgs = {
  distinct_on?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Favourite_Test_Order_By>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


export type Subscription_RootClient_Favourite_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Favourite_Test_Order_By>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


export type Subscription_RootClient_Favourite_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClient_Favourite_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Favourite_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


export type Subscription_RootClient_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Client_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Profile_Order_By>>;
  where?: InputMaybe<Client_Profile_Bool_Exp>;
};


export type Subscription_RootClient_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClient_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Profile_Bool_Exp>;
};


export type Subscription_RootClient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootConsultationArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Subscription_RootConsultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Subscription_RootConsultation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootConsultation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Consultation_Stream_Cursor_Input>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_TestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootLaboratory_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootLaboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootMaterialArgs = {
  distinct_on?: InputMaybe<Array<Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Material_Order_By>>;
  where?: InputMaybe<Material_Bool_Exp>;
};


export type Subscription_RootMaterial_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMaterial_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Material_Stream_Cursor_Input>>;
  where?: InputMaybe<Material_Bool_Exp>;
};


export type Subscription_RootNearby_Unconfirmed_OrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootNurseArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Order_By>>;
  where?: InputMaybe<Nurse_Bool_Exp>;
};


export type Subscription_RootNurse_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNurse_MaterialArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Material_Order_By>>;
  where?: InputMaybe<Nurse_Material_Bool_Exp>;
};


export type Subscription_RootNurse_Material_By_PkArgs = {
  materialId: Scalars['Int'];
  nurseId: Scalars['Int'];
};


export type Subscription_RootNurse_Material_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nurse_Material_Stream_Cursor_Input>>;
  where?: InputMaybe<Nurse_Material_Bool_Exp>;
};


export type Subscription_RootNurse_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nurse_Stream_Cursor_Input>>;
  where?: InputMaybe<Nurse_Bool_Exp>;
};


export type Subscription_RootNurse_TestArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Test_Order_By>>;
  where?: InputMaybe<Nurse_Test_Bool_Exp>;
};


export type Subscription_RootNurse_Test_By_PkArgs = {
  nurseId: Scalars['Int'];
  testId: Scalars['Int'];
};


export type Subscription_RootNurse_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nurse_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Nurse_Test_Bool_Exp>;
};


export type Subscription_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_CollectionArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Subscription_RootOrder_Collection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Subscription_RootOrder_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Collection_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Collection_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Subscription_RootOrder_ConsultationArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


export type Subscription_RootOrder_Consultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Consultation_Order_By>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


export type Subscription_RootOrder_Consultation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Consultation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Consultation_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Consultation_Bool_Exp>;
};


export type Subscription_RootOrder_StateArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Order_By>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


export type Subscription_RootOrder_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_State_EnumArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Enum_Order_By>>;
  where?: InputMaybe<Order_State_Enum_Bool_Exp>;
};


export type Subscription_RootOrder_State_Enum_By_PkArgs = {
  state: Scalars['String'];
};


export type Subscription_RootOrder_State_Enum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_State_Enum_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_State_Enum_Bool_Exp>;
};


export type Subscription_RootOrder_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


export type Subscription_RootOrder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_TestArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


export type Subscription_RootOrder_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Test_ResultArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


export type Subscription_RootOrder_Test_Result_By_PkArgs = {
  fileId: Scalars['Int'];
  orderTestId: Scalars['Int'];
};


export type Subscription_RootOrder_Test_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Test_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


export type Subscription_RootOrder_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


export type Subscription_RootOrder_TrainingArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


export type Subscription_RootOrder_Training_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


export type Subscription_RootOrder_Training_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Training_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Training_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


export type Subscription_RootSearch_TestArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_AggregateArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_Most_PopularArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_Most_Popular_AggregateArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_TagArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_Tag_AggregateArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tag_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_LaboratoriesArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootTest_Laboratories_AggregateArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootTest_MaterialArgs = {
  distinct_on?: InputMaybe<Array<Test_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Material_Order_By>>;
  where?: InputMaybe<Test_Material_Bool_Exp>;
};


export type Subscription_RootTest_Material_By_PkArgs = {
  materialId: Scalars['Int'];
  testId: Scalars['Int'];
};


export type Subscription_RootTest_Material_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Material_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Material_Bool_Exp>;
};


export type Subscription_RootTest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_TagArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Subscription_RootTest_Tag_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_Tag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Tag_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};


export type Subscription_RootTests_By_Collection_Point_And_NurseArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTests_By_Collection_Point_And_Nurse_AggregateArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Subscription_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Subscription_RootTraining_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTraining_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** columns and relationships of "tag" */
export type Tag = {
  __typename?: 'tag';
  id: Scalars['String'];
  /** A computed field, executes function "tag_tag_i18n" */
  tag?: Maybe<Scalars['String']>;
  /** An array relationship */
  testTags: Array<Test_Tag>;
};


/** columns and relationships of "tag" */
export type TagTestTagsArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};

/** aggregated selection of "tag" */
export type Tag_Aggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<Tag_Aggregate_Fields>;
  nodes: Array<Tag>;
};

/** aggregate fields of "tag" */
export type Tag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tag_Max_Fields>;
  min?: Maybe<Tag_Min_Fields>;
};


/** aggregate fields of "tag" */
export type Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Bool_Exp>>;
  _not?: InputMaybe<Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  testTags?: InputMaybe<Test_Tag_Bool_Exp>;
};

/** aggregate max on columns */
export type Tag_Max_Fields = {
  __typename?: 'tag_max_fields';
  id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "tag_tag_i18n" */
  tag?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tag_Min_Fields = {
  __typename?: 'tag_min_fields';
  id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "tag_tag_i18n" */
  tag?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tag". */
export type Tag_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  testTags_aggregate?: InputMaybe<Test_Tag_Aggregate_Order_By>;
};

/** select columns of table "tag" */
export enum Tag_Select_Column {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "tag" */
export type Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "test" */
export type Test = {
  __typename?: 'test';
  /** A computed field, executes function "test_laboratory_tests" */
  availableLaboratoryTests?: Maybe<Array<Laboratory_Test>>;
  bloodlabEligible: Scalars['Boolean'];
  /** An array relationship */
  clientFavouriteTests: Array<Client_Favourite_Test>;
  /** An aggregate relationship */
  clientFavouriteTests_aggregate: Client_Favourite_Test_Aggregate;
  /** A computed field, executes function "test_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "test_available" */
  isAvailable?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  laboratoryTests: Array<Laboratory_Test>;
  /** An aggregate relationship */
  laboratoryTests_aggregate: Laboratory_Test_Aggregate;
  mostPopular: Scalars['Boolean'];
  /** A computed field, executes function "test_name_i18n" */
  name?: Maybe<Scalars['String']>;
  name_i18n: Scalars['jsonb'];
  /** An array relationship */
  nurseTests: Array<Nurse_Test>;
  /** An array relationship */
  orderTests: Array<Order_Test>;
  /** A computed field, executes function "test_preparation_i18n" */
  preparation?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "test_short_name_i18n" */
  shortName?: Maybe<Scalars['String']>;
  svgIcon?: Maybe<Scalars['String']>;
  /** An array relationship */
  testMaterials: Array<Test_Material>;
  /** An array relationship */
  testTags: Array<Test_Tag>;
  /** A computed field, executes function "test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};


/** columns and relationships of "test" */
export type TestAvailableLaboratoryTestsArgs = {
  args: AvailableLaboratoryTests_Test_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestClientFavouriteTestsArgs = {
  distinct_on?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Favourite_Test_Order_By>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestClientFavouriteTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Favourite_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Favourite_Test_Order_By>>;
  where?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestIsAvailableArgs = {
  args: IsAvailable_Test_Args;
};


/** columns and relationships of "test" */
export type TestLaboratoryTestsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestLaboratoryTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestName_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "test" */
export type TestNurseTestsArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Test_Order_By>>;
  where?: InputMaybe<Nurse_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestOrderTestsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestTestMaterialsArgs = {
  distinct_on?: InputMaybe<Array<Test_Material_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Material_Order_By>>;
  where?: InputMaybe<Test_Material_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestTestTagsArgs = {
  distinct_on?: InputMaybe<Array<Test_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Tag_Order_By>>;
  where?: InputMaybe<Test_Tag_Bool_Exp>;
};

export type Test_Aggregate = {
  __typename?: 'test_aggregate';
  aggregate?: Maybe<Test_Aggregate_Fields>;
  nodes: Array<Test>;
};

/** aggregate fields of "test" */
export type Test_Aggregate_Fields = {
  __typename?: 'test_aggregate_fields';
  avg?: Maybe<Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Max_Fields>;
  min?: Maybe<Test_Min_Fields>;
  stddev?: Maybe<Test_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Sum_Fields>;
  var_pop?: Maybe<Test_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Var_Samp_Fields>;
  variance?: Maybe<Test_Variance_Fields>;
};


/** aggregate fields of "test" */
export type Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Test_Avg_Fields = {
  __typename?: 'test_avg_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "test". All fields are combined with a logical 'AND'. */
export type Test_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Bool_Exp>>;
  _not?: InputMaybe<Test_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Bool_Exp>>;
  bloodlabEligible?: InputMaybe<Boolean_Comparison_Exp>;
  clientFavouriteTests?: InputMaybe<Client_Favourite_Test_Bool_Exp>;
  clientFavouriteTests_aggregate?: InputMaybe<Client_Favourite_Test_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratoryTests?: InputMaybe<Laboratory_Test_Bool_Exp>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp>;
  mostPopular?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  nurseTests?: InputMaybe<Nurse_Test_Bool_Exp>;
  orderTests?: InputMaybe<Order_Test_Bool_Exp>;
  preparation?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
  svgIcon?: InputMaybe<String_Comparison_Exp>;
  testMaterials?: InputMaybe<Test_Material_Bool_Exp>;
  testTags?: InputMaybe<Test_Tag_Bool_Exp>;
  waitingTime?: InputMaybe<String_Comparison_Exp>;
};

export type Test_Laboratories_Args = {
  _test_id?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

/** columns and relationships of "test_material" */
export type Test_Material = {
  __typename?: 'test_material';
  /** An object relationship */
  material: Material;
  materialId: Scalars['Int'];
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** order by aggregate values of table "test_material" */
export type Test_Material_Aggregate_Order_By = {
  avg?: InputMaybe<Test_Material_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Test_Material_Max_Order_By>;
  min?: InputMaybe<Test_Material_Min_Order_By>;
  stddev?: InputMaybe<Test_Material_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Test_Material_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Test_Material_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Test_Material_Sum_Order_By>;
  var_pop?: InputMaybe<Test_Material_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Test_Material_Var_Samp_Order_By>;
  variance?: InputMaybe<Test_Material_Variance_Order_By>;
};

/** order by avg() on columns of table "test_material" */
export type Test_Material_Avg_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "test_material". All fields are combined with a logical 'AND'. */
export type Test_Material_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Material_Bool_Exp>>;
  _not?: InputMaybe<Test_Material_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Material_Bool_Exp>>;
  material?: InputMaybe<Material_Bool_Exp>;
  materialId?: InputMaybe<Int_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "test_material" */
export type Test_Material_Max_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "test_material" */
export type Test_Material_Min_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "test_material". */
export type Test_Material_Order_By = {
  material?: InputMaybe<Material_Order_By>;
  materialId?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "test_material" */
export enum Test_Material_Select_Column {
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  TestId = 'testId'
}

/** order by stddev() on columns of table "test_material" */
export type Test_Material_Stddev_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "test_material" */
export type Test_Material_Stddev_Pop_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "test_material" */
export type Test_Material_Stddev_Samp_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "test_material" */
export type Test_Material_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Material_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Material_Stream_Cursor_Value_Input = {
  materialId?: InputMaybe<Scalars['Int']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "test_material" */
export type Test_Material_Sum_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "test_material" */
export type Test_Material_Var_Pop_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "test_material" */
export type Test_Material_Var_Samp_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "test_material" */
export type Test_Material_Variance_Order_By = {
  materialId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Test_Max_Fields = {
  __typename?: 'test_max_fields';
  /** A computed field, executes function "test_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "test_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_preparation_i18n" */
  preparation?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "test_short_name_i18n" */
  shortName?: Maybe<Scalars['String']>;
  svgIcon?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Test_Min_Fields = {
  __typename?: 'test_min_fields';
  /** A computed field, executes function "test_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "test_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_preparation_i18n" */
  preparation?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "test_short_name_i18n" */
  shortName?: Maybe<Scalars['String']>;
  svgIcon?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "test". */
export type Test_Order_By = {
  bloodlabEligible?: InputMaybe<Order_By>;
  clientFavouriteTests_aggregate?: InputMaybe<Client_Favourite_Test_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  mostPopular?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_i18n?: InputMaybe<Order_By>;
  nurseTests_aggregate?: InputMaybe<Nurse_Test_Aggregate_Order_By>;
  orderTests_aggregate?: InputMaybe<Order_Test_Aggregate_Order_By>;
  preparation?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  svgIcon?: InputMaybe<Order_By>;
  testMaterials_aggregate?: InputMaybe<Test_Material_Aggregate_Order_By>;
  testTags_aggregate?: InputMaybe<Test_Tag_Aggregate_Order_By>;
  waitingTime?: InputMaybe<Order_By>;
};

/** select columns of table "test" */
export enum Test_Select_Column {
  /** column name */
  BloodlabEligible = 'bloodlabEligible',
  /** column name */
  Id = 'id',
  /** column name */
  MostPopular = 'mostPopular',
  /** column name */
  NameI18n = 'name_i18n',
  /** column name */
  SvgIcon = 'svgIcon'
}

/** aggregate stddev on columns */
export type Test_Stddev_Fields = {
  __typename?: 'test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_pop on columns */
export type Test_Stddev_Pop_Fields = {
  __typename?: 'test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_samp on columns */
export type Test_Stddev_Samp_Fields = {
  __typename?: 'test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "test" */
export type Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Stream_Cursor_Value_Input = {
  bloodlabEligible?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  mostPopular?: InputMaybe<Scalars['Boolean']>;
  name_i18n?: InputMaybe<Scalars['jsonb']>;
  svgIcon?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Test_Sum_Fields = {
  __typename?: 'test_sum_fields';
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "test_tag" */
export type Test_Tag = {
  __typename?: 'test_tag';
  /** An object relationship */
  Tag: Tag;
  id: Scalars['Int'];
  tag?: Maybe<Scalars['String']>;
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
};

/** order by aggregate values of table "test_tag" */
export type Test_Tag_Aggregate_Order_By = {
  avg?: InputMaybe<Test_Tag_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Test_Tag_Max_Order_By>;
  min?: InputMaybe<Test_Tag_Min_Order_By>;
  stddev?: InputMaybe<Test_Tag_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Test_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Test_Tag_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Test_Tag_Sum_Order_By>;
  var_pop?: InputMaybe<Test_Tag_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Test_Tag_Var_Samp_Order_By>;
  variance?: InputMaybe<Test_Tag_Variance_Order_By>;
};

/** order by avg() on columns of table "test_tag" */
export type Test_Tag_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "test_tag". All fields are combined with a logical 'AND'. */
export type Test_Tag_Bool_Exp = {
  Tag?: InputMaybe<Tag_Bool_Exp>;
  _and?: InputMaybe<Array<Test_Tag_Bool_Exp>>;
  _not?: InputMaybe<Test_Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Tag_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "test_tag" */
export type Test_Tag_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "test_tag" */
export type Test_Tag_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "test_tag". */
export type Test_Tag_Order_By = {
  Tag?: InputMaybe<Tag_Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** select columns of table "test_tag" */
export enum Test_Tag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Tag = 'tag',
  /** column name */
  TestId = 'testId'
}

/** order by stddev() on columns of table "test_tag" */
export type Test_Tag_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "test_tag" */
export type Test_Tag_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "test_tag" */
export type Test_Tag_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "test_tag" */
export type Test_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Tag_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "test_tag" */
export type Test_Tag_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "test_tag" */
export type Test_Tag_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "test_tag" */
export type Test_Tag_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "test_tag" */
export type Test_Tag_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Test_Var_Pop_Fields = {
  __typename?: 'test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate var_samp on columns */
export type Test_Var_Samp_Fields = {
  __typename?: 'test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate variance on columns */
export type Test_Variance_Fields = {
  __typename?: 'test_variance_fields';
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
};

export type Tests_By_Collection_Point_And_Nurse_Args = {
  collectionpointid?: InputMaybe<Scalars['Int']>;
  nurseid?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "training" */
export type Training = {
  __typename?: 'training';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  orderTrainings: Array<Order_Training>;
  /** An aggregate relationship */
  orderTrainings_aggregate: Order_Training_Aggregate;
  /** An object relationship */
  previewFile: File;
  previewFileId: Scalars['Int'];
  price: Scalars['numeric'];
  /** An object relationship */
  videoFile: File;
  videoFileId: Scalars['Int'];
};


/** columns and relationships of "training" */
export type TrainingOrderTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};


/** columns and relationships of "training" */
export type TrainingOrderTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Training_Order_By>>;
  where?: InputMaybe<Order_Training_Bool_Exp>;
};

/** aggregated selection of "training" */
export type Training_Aggregate = {
  __typename?: 'training_aggregate';
  aggregate?: Maybe<Training_Aggregate_Fields>;
  nodes: Array<Training>;
};

export type Training_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Aggregate_Bool_Exp_Count>;
};

export type Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training" */
export type Training_Aggregate_Fields = {
  __typename?: 'training_aggregate_fields';
  avg?: Maybe<Training_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Max_Fields>;
  min?: Maybe<Training_Min_Fields>;
  stddev?: Maybe<Training_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Sum_Fields>;
  var_pop?: Maybe<Training_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Var_Samp_Fields>;
  variance?: Maybe<Training_Variance_Fields>;
};


/** aggregate fields of "training" */
export type Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training" */
export type Training_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Max_Order_By>;
  min?: InputMaybe<Training_Min_Order_By>;
  stddev?: InputMaybe<Training_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Training_Avg_Fields = {
  __typename?: 'training_avg_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training" */
export type Training_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training". All fields are combined with a logical 'AND'. */
export type Training_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Bool_Exp>>;
  _not?: InputMaybe<Training_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Bool_Exp>>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orderTrainings?: InputMaybe<Order_Training_Bool_Exp>;
  orderTrainings_aggregate?: InputMaybe<Order_Training_Aggregate_Bool_Exp>;
  previewFile?: InputMaybe<File_Bool_Exp>;
  previewFileId?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  videoFile?: InputMaybe<File_Bool_Exp>;
  videoFileId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Training_Max_Fields = {
  __typename?: 'training_max_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "training" */
export type Training_Max_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Min_Fields = {
  __typename?: 'training_min_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "training" */
export type Training_Min_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "training". */
export type Training_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  orderTrainings_aggregate?: InputMaybe<Order_Training_Aggregate_Order_By>;
  previewFile?: InputMaybe<File_Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFile?: InputMaybe<File_Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** select columns of table "training" */
export enum Training_Select_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  PreviewFileId = 'previewFileId',
  /** column name */
  Price = 'price',
  /** column name */
  VideoFileId = 'videoFileId'
}

/** aggregate stddev on columns */
export type Training_Stddev_Fields = {
  __typename?: 'training_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training" */
export type Training_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Stddev_Pop_Fields = {
  __typename?: 'training_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training" */
export type Training_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Stddev_Samp_Fields = {
  __typename?: 'training_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training" */
export type Training_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training" */
export type Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Stream_Cursor_Value_Input = {
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  previewFileId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  videoFileId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Training_Sum_Fields = {
  __typename?: 'training_sum_fields';
  id?: Maybe<Scalars['Int']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "training" */
export type Training_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Training_Var_Pop_Fields = {
  __typename?: 'training_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training" */
export type Training_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Var_Samp_Fields = {
  __typename?: 'training_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training" */
export type Training_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Variance_Fields = {
  __typename?: 'training_variance_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training" */
export type Training_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

export type AcceptDifferentDateMutationVariables = Exact<{
  orderId: Scalars['Int'];
  date: Scalars['timestamptz'];
}>;


export type AcceptDifferentDateMutation = { __typename?: 'mutation_root', insert_order_state_one?: { __typename?: 'order_state', id: number } | null, update_order_collection?: { __typename?: 'order_collection_mutation_response', affected_rows: number } | null };

export type AddOrderMutationVariables = Exact<{
  collections: Array<OrderAddCollectionInput> | OrderAddCollectionInput;
  consultations: Array<OrderAddConsultationInput> | OrderAddConsultationInput;
  trainings: Array<OrderAddTrainingInput> | OrderAddTrainingInput;
  discountCodes: Array<Scalars['String']> | Scalars['String'];
  clientConsent1: Scalars['Boolean'];
  clientConsent2: Scalars['Boolean'];
  clientConsent3: Scalars['Boolean'];
}>;


export type AddOrderMutation = { __typename?: 'mutation_root', client_order_add?: { __typename?: 'OrderAddOutput', id: number, paymentToken: string } | null };

export type AddProfileMutationVariables = Exact<{
  clientId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  identity: Scalars['jsonb'];
  phoneNumber: Scalars['String'];
  location: Scalars['geography'];
}>;


export type AddProfileMutation = { __typename?: 'mutation_root', insert_client_profile_one?: { __typename?: 'client_profile', address?: string | null, city?: string | null, id: number, firstName: string, lastName: string, identity: any, phoneNumber: string, postalCode?: string | null, location?: { type: 'Point'; coordinates: [number, number] } | null, isMain?: boolean | null } | null };

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CancelOrderMutation = { __typename?: 'mutation_root', insert_order_state_one?: { __typename?: 'order_state', id: number } | null };

export type ClientContactSendMutationVariables = Exact<{
  message: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type ClientContactSendMutation = { __typename?: 'mutation_root', client_contact_send?: { __typename?: 'ClientContactSendOutput', result: boolean } | null };

export type ConfirmOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type ConfirmOrderMutation = { __typename?: 'mutation_root', insert_order_state_one?: { __typename?: 'order_state', id: number } | null };

export type DeleteClientFavouriteTestMutationVariables = Exact<{
  testId: Scalars['Int'];
  clientId: Scalars['Int'];
}>;


export type DeleteClientFavouriteTestMutation = { __typename?: 'mutation_root', delete_client_favourite_test?: { __typename?: 'client_favourite_test_mutation_response', affected_rows: number } | null };

export type DeleteProfileMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProfileMutation = { __typename?: 'mutation_root', delete_client_profile_by_pk?: { __typename?: 'client_profile', id: number } | null };

export type GetAttentionOrdersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetAttentionOrdersSubscription = { __typename?: 'subscription_root', order: Array<{ __typename?: 'order', id: number, currentState?: string | null, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurse?: { __typename?: 'nurse', name?: string | null } | null }>, orderConsultations: Array<{ __typename?: 'order_consultation', preferredDate: any, consultation: { __typename?: 'consultation', id: number, name?: string | null } }>, orderTrainings: Array<{ __typename?: 'order_training', training: { __typename?: 'training', id: number, name?: string | null } }>, orderTests: Array<{ __typename?: 'order_test', test: { __typename?: 'test', name?: string | null, shortName?: string | null } }> }> };

export type GetAvailableLaboratoriesSubscriptionVariables = Exact<{
  point: Scalars['geography'];
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAvailableLaboratoriesSubscription = { __typename?: 'subscription_root', available_laboratory: Array<{ __typename?: 'laboratory', id: number, name: string, logoFile?: { __typename?: 'file', id: number } | null }> };

export type GetAvailableTestTagsSubscriptionVariables = Exact<{
  point: Scalars['geography'];
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAvailableTestTagsSubscription = { __typename?: 'subscription_root', available_test_tag: Array<{ __typename?: 'test_tag', Tag: { __typename?: 'tag', id: string, tag?: string | null } }> };

export type GetAvailableTestsByQuerySubscriptionVariables = Exact<{
  query: Scalars['String'];
  point?: InputMaybe<Scalars['geography']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAvailableTestsByQuerySubscription = { __typename?: 'subscription_root', search_test: Array<{ __typename?: 'test', id: number, shortName?: string | null, name?: string | null, description?: string | null, svgIcon?: string | null, availableLaboratoryTests?: Array<{ __typename?: 'laboratory_test', price: any }> | null }> };

export type GetAvailableTestsByTagSubscriptionVariables = Exact<{
  tag: Scalars['String'];
  point?: InputMaybe<Scalars['geography']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAvailableTestsByTagSubscription = { __typename?: 'subscription_root', search_test_tag: Array<{ __typename?: 'test', id: number, shortName?: string | null, name?: string | null, description?: string | null, svgIcon?: string | null, availableLaboratoryTests?: Array<{ __typename?: 'laboratory_test', price: any }> | null }> };

export type GetAvailableTestsMostPopularSubscriptionVariables = Exact<{
  point?: InputMaybe<Scalars['geography']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAvailableTestsMostPopularSubscription = { __typename?: 'subscription_root', search_test_most_popular: Array<{ __typename?: 'test', id: number, shortName?: string | null, name?: string | null, description?: string | null, svgIcon?: string | null, availableLaboratoryTests?: Array<{ __typename?: 'laboratory_test', price: any }> | null }> };

export type GetClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetClientQuery = { __typename?: 'query_root', client_by_pk?: { __typename?: 'client', id: number, newsletterConsent: boolean, pushConsent: boolean } | null };

export type GetClientFavouriteTestsSubscriptionVariables = Exact<{
  point?: InputMaybe<Scalars['geography']>;
}>;


export type GetClientFavouriteTestsSubscription = { __typename?: 'subscription_root', client_favourite_test: Array<{ __typename?: 'client_favourite_test', test: { __typename?: 'test', id: number, shortName?: string | null, name?: string | null, description?: string | null, svgIcon?: string | null, availableLaboratoryTests?: Array<{ __typename?: 'laboratory_test', price: any }> | null } }> };

export type GetCurrentOrdersSubscriptionVariables = Exact<{
  date: Scalars['timestamptz'];
}>;


export type GetCurrentOrdersSubscription = { __typename?: 'subscription_root', order: Array<{ __typename?: 'order', id: number, date?: any | null, currentState?: string | null, orderTests: Array<{ __typename?: 'order_test', test: { __typename?: 'test', name?: string | null, shortName?: string | null } }>, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurse?: { __typename?: 'nurse', name?: string | null } | null }>, orderConsultations: Array<{ __typename?: 'order_consultation', preferredDate: any, consultation: { __typename?: 'consultation', id: number, name?: string | null } }>, orderTrainings: Array<{ __typename?: 'order_training', training: { __typename?: 'training', id: number, name?: string | null } }> }> };

export type GetMainProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMainProfileQuery = { __typename?: 'query_root', client_profile: Array<{ __typename?: 'client_profile', address?: string | null, city?: string | null, id: number, firstName: string, lastName: string, identity: any, phoneNumber: string, postalCode?: string | null, location?: { type: 'Point'; coordinates: [number, number] } | null, isMain?: boolean | null }> };

export type GetOrderSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrderSubscription = { __typename?: 'subscription_root', order_by_pk?: { __typename?: 'order', id: number, currentState?: string | null, firstStateDate?: any | null, paymentToken?: string | null, clientConsent1?: boolean | null, clientConsent2?: boolean | null, clientConsent3?: boolean | null, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurse?: { __typename?: 'nurse', name?: string | null } | null }>, orderConsultations: Array<{ __typename?: 'order_consultation', firstName: string, lastName: string, identity: any, preferredDate: any, consultation: { __typename?: 'consultation', id: number, name?: string | null, description?: string | null } }>, orderTrainings: Array<{ __typename?: 'order_training', training: { __typename?: 'training', id: number, name?: string | null, description?: string | null, videoFile: { __typename?: 'file', id: number }, previewFile: { __typename?: 'file', id: number } } }>, orderStates: Array<{ __typename?: 'order_state', date: any, state: Order_State_Enum_Enum, extra?: any | null }>, orderTests: Array<{ __typename?: 'order_test', id: number, firstName: string, lastName: string, identity?: any | null, test: { __typename?: 'test', id: number, name?: string | null, shortName?: string | null, description?: string | null, waitingTime?: string | null, preparation?: string | null, bloodlabEligible: boolean }, testResults: Array<{ __typename?: 'order_test_result', date?: any | null, criticalParameter: boolean, file: { __typename?: 'file', id: number, name: string } }> }> } | null };

export type GetPastOrdersSubscriptionVariables = Exact<{
  date: Scalars['timestamptz'];
}>;


export type GetPastOrdersSubscription = { __typename?: 'subscription_root', order: Array<{ __typename?: 'order', id: number, currentState?: string | null, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurse?: { __typename?: 'nurse', name?: string | null } | null }>, orderConsultations: Array<{ __typename?: 'order_consultation', preferredDate: any, consultation: { __typename?: 'consultation', id: number, name?: string | null } }>, orderTrainings: Array<{ __typename?: 'order_training', training: { __typename?: 'training', id: number, name?: string | null } }>, orderTests: Array<{ __typename?: 'order_test', test: { __typename?: 'test', name?: string | null, shortName?: string | null } }> }> };

export type GetProfileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProfileQuery = { __typename?: 'query_root', client_profile_by_pk?: { __typename?: 'client_profile', address?: string | null, city?: string | null, id: number, firstName: string, lastName: string, identity: any, phoneNumber: string, postalCode?: string | null, location?: { type: 'Point'; coordinates: [number, number] } | null, isMain?: boolean | null } | null };

export type GetProfilesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetProfilesSubscription = { __typename?: 'subscription_root', client_profile: Array<{ __typename?: 'client_profile', address?: string | null, city?: string | null, id: number, firstName: string, lastName: string, identity: any, phoneNumber: string, postalCode?: string | null, location?: { type: 'Point'; coordinates: [number, number] } | null, isMain?: boolean | null }> };

export type GetTestFavouriteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTestFavouriteQuery = { __typename?: 'query_root', client_favourite_test_aggregate: { __typename?: 'client_favourite_test_aggregate', aggregate?: { __typename?: 'client_favourite_test_aggregate_fields', count: number } | null } };

export type InsertClientFavouriteTestMutationVariables = Exact<{
  testId: Scalars['Int'];
  clientId: Scalars['Int'];
}>;


export type InsertClientFavouriteTestMutation = { __typename?: 'mutation_root', insert_client_favourite_test_one?: { __typename?: 'client_favourite_test', id: number } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'mutation_root', client_logout: { __typename?: 'ClientLogoutOutput', result: string } };

export type RejectDifferentDateMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type RejectDifferentDateMutation = { __typename?: 'mutation_root', insert_order_state_one?: { __typename?: 'order_state', id: number } | null };

export type SendComplaintMutationVariables = Exact<{
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
  attachments: Array<ClientComplaintSendAttachment> | ClientComplaintSendAttachment;
}>;


export type SendComplaintMutation = { __typename?: 'mutation_root', client_complaint_send?: { __typename?: 'ClientComplaintSendOutput', result: boolean } | null };

export type UpdateClientConsentMutationVariables = Exact<{
  id: Scalars['Int'];
  newsletterConsent: Scalars['Boolean'];
  pushConsent: Scalars['Boolean'];
}>;


export type UpdateClientConsentMutation = { __typename?: 'mutation_root', update_client_by_pk?: { __typename?: 'client', id: number } | null };

export type UpdateOrderClientConsentsMutationVariables = Exact<{
  orderId: Scalars['Int'];
  clientConsent1: Scalars['Boolean'];
  clientConsent2: Scalars['Boolean'];
  clientConsent3: Scalars['Boolean'];
}>;


export type UpdateOrderClientConsentsMutation = { __typename?: 'mutation_root', update_order_by_pk?: { __typename?: 'order', id: number } | null };

export type UpdateOrderTestIdentitiesMutationVariables = Exact<{
  orderTestIds: Array<Scalars['Int']> | Scalars['Int'];
  identity: Scalars['jsonb'];
}>;


export type UpdateOrderTestIdentitiesMutation = { __typename?: 'mutation_root', update_order_test?: { __typename?: 'order_test_mutation_response', returning: Array<{ __typename?: 'order_test', id: number }> } | null };

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  identity: Scalars['jsonb'];
  phoneNumber: Scalars['String'];
  location: Scalars['geography'];
}>;


export type UpdateProfileMutation = { __typename?: 'mutation_root', update_client_profile_by_pk?: { __typename?: 'client_profile', address?: string | null, city?: string | null, id: number, firstName: string, lastName: string, identity: any, phoneNumber: string, postalCode?: string | null, location?: { type: 'Point'; coordinates: [number, number] } | null, isMain?: boolean | null } | null };

export type VerifyKdrMutationVariables = Exact<{
  cardNumber: Scalars['String'];
}>;


export type VerifyKdrMutation = { __typename?: 'mutation_root', client_verify_kdr?: { __typename?: 'ClientVerifyKdrOutput', valid: boolean } | null };

export type ClientProfileFragment = { __typename?: 'client_profile', address?: string | null, city?: string | null, id: number, firstName: string, lastName: string, identity: any, phoneNumber: string, postalCode?: string | null, location?: { type: 'Point'; coordinates: [number, number] } | null, isMain?: boolean | null };

export type LaboratoryFragment = { __typename?: 'laboratory', id: number, name: string, logoFile?: { __typename?: 'file', id: number } | null };

export type SearchTestFragment = { __typename?: 'test', id: number, shortName?: string | null, name?: string | null, description?: string | null, svgIcon?: string | null, availableLaboratoryTests?: Array<{ __typename?: 'laboratory_test', price: any }> | null };

export const ClientProfileFragmentDoc = gql`
    fragment clientProfile on client_profile {
  address
  city
  id
  firstName
  lastName
  identity
  phoneNumber
  postalCode
  location
  isMain
}
    `;
export const LaboratoryFragmentDoc = gql`
    fragment laboratory on laboratory {
  id
  name
  logoFile {
    id
  }
}
    `;
export const SearchTestFragmentDoc = gql`
    fragment searchTest on test {
  id
  shortName
  name
  description
  svgIcon
  availableLaboratoryTests(args: {point: $point}) {
    price
  }
}
    `;
export const AcceptDifferentDateDocument = gql`
    mutation AcceptDifferentDate($orderId: Int!, $date: timestamptz!) {
  insert_order_state_one(
    object: {state: DifferentDateAccepted, orderId: $orderId}
  ) {
    id
  }
  update_order_collection(
    where: {orderId: {_eq: $orderId}}
    _set: {collectionDate: $date}
  ) {
    affected_rows
  }
}
    `;

export function useAcceptDifferentDateMutation() {
  return Urql.useMutation<AcceptDifferentDateMutation, AcceptDifferentDateMutationVariables>(AcceptDifferentDateDocument);
};
export const AddOrderDocument = gql`
    mutation AddOrder($collections: [OrderAddCollectionInput!]!, $consultations: [OrderAddConsultationInput!]!, $trainings: [OrderAddTrainingInput!]!, $discountCodes: [String!]!, $clientConsent1: Boolean!, $clientConsent2: Boolean!, $clientConsent3: Boolean!) {
  client_order_add(
    order: {collections: $collections, consultations: $consultations, trainings: $trainings, discountCodes: $discountCodes, clientConsent1: $clientConsent1, clientConsent2: $clientConsent2, clientConsent3: $clientConsent3}
  ) {
    id
    paymentToken
  }
}
    `;

export function useAddOrderMutation() {
  return Urql.useMutation<AddOrderMutation, AddOrderMutationVariables>(AddOrderDocument);
};
export const AddProfileDocument = gql`
    mutation AddProfile($clientId: Int!, $firstName: String!, $lastName: String!, $city: String!, $address: String!, $postalCode: String!, $identity: jsonb!, $phoneNumber: String!, $location: geography!) {
  insert_client_profile_one(
    object: {clientId: $clientId, address: $address, city: $city, firstName: $firstName, lastName: $lastName, identity: $identity, phoneNumber: $phoneNumber, postalCode: $postalCode, location: $location}
  ) {
    ...clientProfile
  }
}
    ${ClientProfileFragmentDoc}`;

export function useAddProfileMutation() {
  return Urql.useMutation<AddProfileMutation, AddProfileMutationVariables>(AddProfileDocument);
};
export const CancelOrderDocument = gql`
    mutation CancelOrder($orderId: Int!) {
  insert_order_state_one(object: {state: Cancelled, orderId: $orderId}) {
    id
  }
}
    `;

export function useCancelOrderMutation() {
  return Urql.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument);
};
export const ClientContactSendDocument = gql`
    mutation ClientContactSend($message: String!, $email: String) {
  client_contact_send(input: {message: $message, email: $email}) {
    result
  }
}
    `;

export function useClientContactSendMutation() {
  return Urql.useMutation<ClientContactSendMutation, ClientContactSendMutationVariables>(ClientContactSendDocument);
};
export const ConfirmOrderDocument = gql`
    mutation ConfirmOrder($orderId: Int!) {
  insert_order_state_one(object: {state: Confirmed, orderId: $orderId}) {
    id
  }
}
    `;

export function useConfirmOrderMutation() {
  return Urql.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument);
};
export const DeleteClientFavouriteTestDocument = gql`
    mutation DeleteClientFavouriteTest($testId: Int!, $clientId: Int!) {
  delete_client_favourite_test(
    where: {testId: {_eq: $testId}, clientId: {_eq: $clientId}}
  ) {
    affected_rows
  }
}
    `;

export function useDeleteClientFavouriteTestMutation() {
  return Urql.useMutation<DeleteClientFavouriteTestMutation, DeleteClientFavouriteTestMutationVariables>(DeleteClientFavouriteTestDocument);
};
export const DeleteProfileDocument = gql`
    mutation DeleteProfile($id: Int!) {
  delete_client_profile_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteProfileMutation() {
  return Urql.useMutation<DeleteProfileMutation, DeleteProfileMutationVariables>(DeleteProfileDocument);
};
export const GetAttentionOrdersDocument = gql`
    subscription GetAttentionOrders {
  order(
    where: {currentState: {_eq: "AwaitingClientConfirmation"}}
    order_by: {orderCollections_aggregate: {min: {collectionDate: asc}}}
  ) {
    id
    orderCollections {
      collectionDate
      nurse {
        name
      }
    }
    orderCollections {
      collectionDate
      nurse {
        name
      }
    }
    orderConsultations {
      preferredDate
      consultation {
        id
        name
      }
    }
    orderTrainings {
      training {
        id
        name
      }
    }
    currentState
    orderTests {
      test {
        name
        shortName
      }
    }
  }
}
    `;

export function useGetAttentionOrdersSubscription<TData = GetAttentionOrdersSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetAttentionOrdersSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetAttentionOrdersSubscription, TData>) {
  return Urql.useSubscription<GetAttentionOrdersSubscription, TData, GetAttentionOrdersSubscriptionVariables>({ query: GetAttentionOrdersDocument, ...options }, handler);
};
export const GetAvailableLaboratoriesDocument = gql`
    subscription GetAvailableLaboratories($point: geography!, $limit: Int) {
  available_laboratory(args: {point: $point, _limit: $limit}) {
    ...laboratory
  }
}
    ${LaboratoryFragmentDoc}`;

export function useGetAvailableLaboratoriesSubscription<TData = GetAvailableLaboratoriesSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetAvailableLaboratoriesSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetAvailableLaboratoriesSubscription, TData>) {
  return Urql.useSubscription<GetAvailableLaboratoriesSubscription, TData, GetAvailableLaboratoriesSubscriptionVariables>({ query: GetAvailableLaboratoriesDocument, ...options }, handler);
};
export const GetAvailableTestTagsDocument = gql`
    subscription GetAvailableTestTags($point: geography!, $limit: Int) {
  available_test_tag(args: {point: $point, _limit: $limit}) {
    Tag {
      id
      tag
    }
  }
}
    `;

export function useGetAvailableTestTagsSubscription<TData = GetAvailableTestTagsSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetAvailableTestTagsSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetAvailableTestTagsSubscription, TData>) {
  return Urql.useSubscription<GetAvailableTestTagsSubscription, TData, GetAvailableTestTagsSubscriptionVariables>({ query: GetAvailableTestTagsDocument, ...options }, handler);
};
export const GetAvailableTestsByQueryDocument = gql`
    subscription GetAvailableTestsByQuery($query: String!, $point: geography, $limit: Int = 20, $offset: Int = 0) {
  search_test(
    args: {query: $query, point: $point, _limit: $limit, _offset: $offset}
  ) {
    ...searchTest
  }
}
    ${SearchTestFragmentDoc}`;

export function useGetAvailableTestsByQuerySubscription<TData = GetAvailableTestsByQuerySubscription>(options: Omit<Urql.UseSubscriptionArgs<GetAvailableTestsByQuerySubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetAvailableTestsByQuerySubscription, TData>) {
  return Urql.useSubscription<GetAvailableTestsByQuerySubscription, TData, GetAvailableTestsByQuerySubscriptionVariables>({ query: GetAvailableTestsByQueryDocument, ...options }, handler);
};
export const GetAvailableTestsByTagDocument = gql`
    subscription GetAvailableTestsByTag($tag: String!, $point: geography, $limit: Int = 20, $offset: Int = 0) {
  search_test_tag(
    args: {_tag: $tag, point: $point, _limit: $limit, _offset: $offset}
  ) {
    ...searchTest
  }
}
    ${SearchTestFragmentDoc}`;

export function useGetAvailableTestsByTagSubscription<TData = GetAvailableTestsByTagSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetAvailableTestsByTagSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetAvailableTestsByTagSubscription, TData>) {
  return Urql.useSubscription<GetAvailableTestsByTagSubscription, TData, GetAvailableTestsByTagSubscriptionVariables>({ query: GetAvailableTestsByTagDocument, ...options }, handler);
};
export const GetAvailableTestsMostPopularDocument = gql`
    subscription GetAvailableTestsMostPopular($point: geography, $limit: Int = 20, $offset: Int = 0) {
  search_test_most_popular(
    args: {point: $point, _limit: $limit, _offset: $offset}
  ) {
    ...searchTest
  }
}
    ${SearchTestFragmentDoc}`;

export function useGetAvailableTestsMostPopularSubscription<TData = GetAvailableTestsMostPopularSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetAvailableTestsMostPopularSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetAvailableTestsMostPopularSubscription, TData>) {
  return Urql.useSubscription<GetAvailableTestsMostPopularSubscription, TData, GetAvailableTestsMostPopularSubscriptionVariables>({ query: GetAvailableTestsMostPopularDocument, ...options }, handler);
};
export const GetClientDocument = gql`
    query GetClient($id: Int!) {
  client_by_pk(id: $id) {
    id
    newsletterConsent
    pushConsent
  }
}
    `;

export function useGetClientQuery(options: Omit<Urql.UseQueryArgs<GetClientQueryVariables>, 'query'>) {
  return Urql.useQuery<GetClientQuery, GetClientQueryVariables>({ query: GetClientDocument, ...options });
};
export const GetClientFavouriteTestsDocument = gql`
    subscription GetClientFavouriteTests($point: geography) {
  client_favourite_test {
    test {
      ...searchTest
    }
  }
}
    ${SearchTestFragmentDoc}`;

export function useGetClientFavouriteTestsSubscription<TData = GetClientFavouriteTestsSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetClientFavouriteTestsSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetClientFavouriteTestsSubscription, TData>) {
  return Urql.useSubscription<GetClientFavouriteTestsSubscription, TData, GetClientFavouriteTestsSubscriptionVariables>({ query: GetClientFavouriteTestsDocument, ...options }, handler);
};
export const GetCurrentOrdersDocument = gql`
    subscription GetCurrentOrders($date: timestamptz!) {
  order(
    where: {_or: [{currentStateDate: {_gte: $date}}, {date: {_gte: $date}}]}
    order_by: {date: desc}
  ) {
    id
    date
    currentState
    orderTests {
      test {
        name
        shortName
      }
    }
    orderCollections {
      collectionDate
      nurse {
        name
      }
    }
    orderConsultations {
      preferredDate
      consultation {
        id
        name
      }
    }
    orderTrainings {
      training {
        id
        name
      }
    }
  }
}
    `;

export function useGetCurrentOrdersSubscription<TData = GetCurrentOrdersSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetCurrentOrdersSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetCurrentOrdersSubscription, TData>) {
  return Urql.useSubscription<GetCurrentOrdersSubscription, TData, GetCurrentOrdersSubscriptionVariables>({ query: GetCurrentOrdersDocument, ...options }, handler);
};
export const GetMainProfileDocument = gql`
    query GetMainProfile {
  client_profile(order_by: {isMain: desc_nulls_last}, limit: 1) {
    ...clientProfile
  }
}
    ${ClientProfileFragmentDoc}`;

export function useGetMainProfileQuery(options?: Omit<Urql.UseQueryArgs<GetMainProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMainProfileQuery, GetMainProfileQueryVariables>({ query: GetMainProfileDocument, ...options });
};
export const GetOrderDocument = gql`
    subscription GetOrder($id: Int!) {
  order_by_pk(id: $id) {
    id
    orderCollections {
      collectionDate
      nurse {
        name
      }
    }
    orderConsultations {
      firstName
      lastName
      identity
      preferredDate
      consultation {
        id
        name
        description
      }
    }
    orderTrainings {
      training {
        id
        name
        description
        videoFile {
          id
        }
        previewFile {
          id
        }
      }
    }
    currentState
    firstStateDate
    orderStates(order_by: {date: desc}, limit: 1) {
      date
      state
      extra
    }
    orderTests {
      id
      test {
        id
        name
        shortName
        description
        waitingTime
        preparation
        bloodlabEligible
      }
      testResults {
        file {
          id
          name
        }
        date
        criticalParameter
      }
      firstName
      lastName
      identity
    }
    paymentToken
    clientConsent1
    clientConsent2
    clientConsent3
  }
}
    `;

export function useGetOrderSubscription<TData = GetOrderSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetOrderSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetOrderSubscription, TData>) {
  return Urql.useSubscription<GetOrderSubscription, TData, GetOrderSubscriptionVariables>({ query: GetOrderDocument, ...options }, handler);
};
export const GetPastOrdersDocument = gql`
    subscription GetPastOrders($date: timestamptz!) {
  order(
    where: {_or: [{currentStateDate: {_lt: $date}}, {date: {_lt: $date}}]}
    order_by: {date: desc}
  ) {
    id
    orderCollections {
      collectionDate
      nurse {
        name
      }
    }
    orderCollections {
      collectionDate
      nurse {
        name
      }
    }
    orderConsultations {
      preferredDate
      consultation {
        id
        name
      }
    }
    orderTrainings {
      training {
        id
        name
      }
    }
    currentState
    orderTests {
      test {
        name
        shortName
      }
    }
  }
}
    `;

export function useGetPastOrdersSubscription<TData = GetPastOrdersSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetPastOrdersSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetPastOrdersSubscription, TData>) {
  return Urql.useSubscription<GetPastOrdersSubscription, TData, GetPastOrdersSubscriptionVariables>({ query: GetPastOrdersDocument, ...options }, handler);
};
export const GetProfileDocument = gql`
    query GetProfile($id: Int!) {
  client_profile_by_pk(id: $id) {
    ...clientProfile
  }
}
    ${ClientProfileFragmentDoc}`;

export function useGetProfileQuery(options: Omit<Urql.UseQueryArgs<GetProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProfileQuery, GetProfileQueryVariables>({ query: GetProfileDocument, ...options });
};
export const GetProfilesDocument = gql`
    subscription GetProfiles {
  client_profile(order_by: {isMain: desc_nulls_last}) {
    ...clientProfile
  }
}
    ${ClientProfileFragmentDoc}`;

export function useGetProfilesSubscription<TData = GetProfilesSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetProfilesSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetProfilesSubscription, TData>) {
  return Urql.useSubscription<GetProfilesSubscription, TData, GetProfilesSubscriptionVariables>({ query: GetProfilesDocument, ...options }, handler);
};
export const GetTestFavouriteDocument = gql`
    query GetTestFavourite($id: Int!) {
  client_favourite_test_aggregate(where: {testId: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}
    `;

export function useGetTestFavouriteQuery(options: Omit<Urql.UseQueryArgs<GetTestFavouriteQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTestFavouriteQuery, GetTestFavouriteQueryVariables>({ query: GetTestFavouriteDocument, ...options });
};
export const InsertClientFavouriteTestDocument = gql`
    mutation InsertClientFavouriteTest($testId: Int!, $clientId: Int!) {
  insert_client_favourite_test_one(object: {testId: $testId, clientId: $clientId}) {
    id
  }
}
    `;

export function useInsertClientFavouriteTestMutation() {
  return Urql.useMutation<InsertClientFavouriteTestMutation, InsertClientFavouriteTestMutationVariables>(InsertClientFavouriteTestDocument);
};
export const LogoutDocument = gql`
    mutation Logout {
  client_logout {
    result
  }
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const RejectDifferentDateDocument = gql`
    mutation RejectDifferentDate($orderId: Int!) {
  insert_order_state_one(
    object: {state: DifferentDateRejected, orderId: $orderId}
  ) {
    id
  }
}
    `;

export function useRejectDifferentDateMutation() {
  return Urql.useMutation<RejectDifferentDateMutation, RejectDifferentDateMutationVariables>(RejectDifferentDateDocument);
};
export const SendComplaintDocument = gql`
    mutation SendComplaint($message: String!, $name: String!, $subject: String!, $attachments: [ClientComplaintSendAttachment!]!) {
  client_complaint_send(
    input: {name: $name, message: $message, subject: $subject, attachments: $attachments}
  ) {
    result
  }
}
    `;

export function useSendComplaintMutation() {
  return Urql.useMutation<SendComplaintMutation, SendComplaintMutationVariables>(SendComplaintDocument);
};
export const UpdateClientConsentDocument = gql`
    mutation UpdateClientConsent($id: Int!, $newsletterConsent: Boolean!, $pushConsent: Boolean!) {
  update_client_by_pk(
    pk_columns: {id: $id}
    _set: {newsletterConsent: $newsletterConsent, pushConsent: $pushConsent}
  ) {
    id
  }
}
    `;

export function useUpdateClientConsentMutation() {
  return Urql.useMutation<UpdateClientConsentMutation, UpdateClientConsentMutationVariables>(UpdateClientConsentDocument);
};
export const UpdateOrderClientConsentsDocument = gql`
    mutation UpdateOrderClientConsents($orderId: Int!, $clientConsent1: Boolean!, $clientConsent2: Boolean!, $clientConsent3: Boolean!) {
  update_order_by_pk(
    pk_columns: {id: $orderId}
    _set: {clientConsent1: $clientConsent1, clientConsent2: $clientConsent2, clientConsent3: $clientConsent3}
  ) {
    id
  }
}
    `;

export function useUpdateOrderClientConsentsMutation() {
  return Urql.useMutation<UpdateOrderClientConsentsMutation, UpdateOrderClientConsentsMutationVariables>(UpdateOrderClientConsentsDocument);
};
export const UpdateOrderTestIdentitiesDocument = gql`
    mutation UpdateOrderTestIdentities($orderTestIds: [Int!]!, $identity: jsonb!) {
  update_order_test(
    where: {id: {_in: $orderTestIds}}
    _set: {identity: $identity}
  ) {
    returning {
      id
    }
  }
}
    `;

export function useUpdateOrderTestIdentitiesMutation() {
  return Urql.useMutation<UpdateOrderTestIdentitiesMutation, UpdateOrderTestIdentitiesMutationVariables>(UpdateOrderTestIdentitiesDocument);
};
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($id: Int!, $firstName: String!, $lastName: String!, $city: String!, $address: String!, $postalCode: String!, $identity: jsonb!, $phoneNumber: String!, $location: geography!) {
  update_client_profile_by_pk(
    pk_columns: {id: $id}
    _set: {address: $address, city: $city, firstName: $firstName, lastName: $lastName, identity: $identity, phoneNumber: $phoneNumber, postalCode: $postalCode, location: $location}
  ) {
    ...clientProfile
  }
}
    ${ClientProfileFragmentDoc}`;

export function useUpdateProfileMutation() {
  return Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument);
};
export const VerifyKdrDocument = gql`
    mutation VerifyKDR($cardNumber: String!) {
  client_verify_kdr(cardNumber: $cardNumber) {
    valid
  }
}
    `;

export function useVerifyKdrMutation() {
  return Urql.useMutation<VerifyKdrMutation, VerifyKdrMutationVariables>(VerifyKdrDocument);
};