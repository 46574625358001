import { createContext } from 'react'
import useLocalStorage from 'use-local-storage'
import {
  Client_Profile,
  ClientProfileFragment,
  SearchTestFragment,
} from '../generated/urql.client'
import {
  ConsultationFragment,
  TrainingFragment,
} from '../generated/urql.anonymous'

export type Basket = ReturnType<typeof useBasket>
export const BasketContext = createContext<Basket>({
  items: [],
  profile: undefined,
  overrideAddress: undefined,
  address: undefined,
  discountCodes: [],
  addItem: () => {},
  setDiscountCodes: () => {},
  removeItem: () => {},
  changeItem: () => {},
  setProfile: () => {},
  setOverrideAddress: () => {},
  clear: () => {},
  remove: () => {},
  logout: () => {},
  onProfileUpdated: () => {},
})

export type TestBasketItem = {
  test: SearchTestFragment
  profile?: Pick<
    ClientProfileFragment,
    'id' | 'firstName' | 'lastName' | 'identity'
  >
}

export type ConsultationBasketItem = {
  consultation: ConsultationFragment
  preferredDate?: string
  profile?: Pick<
    ClientProfileFragment,
    'id' | 'firstName' | 'lastName' | 'identity' | 'phoneNumber'
  >
}

export type TrainingBasketItem = {
  training: TrainingFragment
}

export const isTestBasketItem = (
  basketItem: BasketItem
): basketItem is TestBasketItem => 'test' in basketItem
export const isConsultationBasketItem = (
  basketItem: BasketItem
): basketItem is ConsultationBasketItem => 'consultation' in basketItem
export const isTrainingBasketItem = (
  basketItem: BasketItem
): basketItem is TrainingBasketItem => 'training' in basketItem
export const isSameBasketItem = (a: BasketItem) => (b: BasketItem) => {
  if (isTestBasketItem(a) && isTestBasketItem(b)) {
    return a.test.id === b.test.id && a.profile?.id === b.profile?.id
  }
  if (isConsultationBasketItem(a) && isConsultationBasketItem(b)) {
    return (
      a.consultation.id === b.consultation.id && a.profile?.id === b.profile?.id
    )
  }
  if (isTrainingBasketItem(a) && isTrainingBasketItem(b)) {
    return a.training.id === b.training.id
  }
  return false
}

type BasketItem = TestBasketItem | ConsultationBasketItem | TrainingBasketItem

type StoredBasket = {
  items: BasketItem[]
  discountCodes: string[]
  profile?: ClientProfileFragment
  overrideAddress?: Pick<
    Client_Profile,
    'address' | 'postalCode' | 'city' | 'location'
  > &
    Partial<{ phoneNumber: Client_Profile['phoneNumber'] }>
}

export function useBasket() {
  const [basket, setBasket] = useLocalStorage<StoredBasket>('basket', {
    items: [],
    discountCodes: [],
  })

  return {
    items: basket.items || [],
    profile: basket.profile,
    discountCodes: basket.discountCodes || [],
    overrideAddress: basket.overrideAddress,
    address: basket.overrideAddress || basket.profile,
    setProfile: (profile: StoredBasket['profile']) => {
      setBasket({
        ...basket,
        profile,
        overrideAddress: undefined,
      })
    },

    onProfileUpdated: (profile: StoredBasket['profile']) => {
      if (basket.profile?.id === profile?.id) {
        setBasket({ ...basket, profile })
      }

      setBasket({
        ...basket,
        items: basket.items.map((i) =>
          (isTestBasketItem(i) || isConsultationBasketItem(i)) &&
          i.profile?.id === profile?.id
            ? { ...i, profile }
            : i
        ),
      })
    },

    setDiscountCodes: (discountCodes: string[]) => {
      const b = {
        ...basket,
        discountCodes,
      }
      setBasket(b)
    },

    addItem: (item: BasketItem) => {
      if ((basket.items || []).find(isSameBasketItem(item))) {
        return
      }

      const b = { ...basket, items: [...(basket.items || []), item] }
      setBasket(b)
    },

    removeItem: (...basketItems: BasketItem[]) => {
      const b = {
        ...basket,
        items:
          basket.items.filter(
            (item) => !basketItems.find(isSameBasketItem(item))
          ) || [],
      }
      setBasket(b)
    },

    changeItem: (existingItem: BasketItem, updatedItem: BasketItem) => {
      const items = basket.items.slice()
      items.splice(
        basket.items.findIndex(isSameBasketItem(existingItem)),
        1,
        updatedItem
      )

      const b = {
        ...basket,
        items,
      }
      setBasket(b)
    },

    setOverrideAddress: (overrideAddress?: StoredBasket['overrideAddress']) => {
      setBasket({ ...basket, overrideAddress })
    },

    clear: () => {
      setBasket({
        ...basket,
        overrideAddress: undefined,
        items: [],
        discountCodes: [],
      })
    },

    remove: () => {
      setBasket({
        items: [],
        discountCodes: [],
      })
    },

    logout: () => {
      setBasket({
        items: [],
        discountCodes: [],
        overrideAddress: basket.overrideAddress
          ? { ...basket.overrideAddress }
          : basket.profile
          ? { ...basket.profile }
          : undefined,
      })
    },
  }
}
