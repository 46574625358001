import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  GetClientDocument,
  GetClientQuery,
  GetClientQueryVariables,
  GetProfilesSubscription,
  useAddProfileMutation,
  useDeleteProfileMutation,
  useGetProfilesSubscription,
  useUpdateClientConsentMutation,
  useUpdateProfileMutation,
} from '../generated/urql.client'
import { useI18n } from '../provider/i18n'
import { Link } from 'react-router-dom'
import {
  MdAspectRatio,
  MdFlag,
  MdKeyboardArrowRight,
  MdOutlineSmartphone,
  MdPerson,
} from 'react-icons/md'
import { Blue300, Blue600, Green600 } from '../lib/colors'
import { TailSpin } from 'react-loader-spinner'
import { AuthContext } from '../provider/auth'
import { useClient } from 'urql'
import { Controller, useForm } from 'react-hook-form'
import { omit } from 'lodash'
import { isProfileComplete } from '../lib/profile'
import Switch from 'react-switch'
import ProfileAddIcon from '../assets/profile-add.svg'
import { Disclosure, Transition } from '@headlessui/react'
import { BasketContext } from '../provider/basket'
import { Point } from '../lib/geography'
import { ClientIdentity } from '../lib/identity'
import Countries from '../assets/countries.json'
import isPeselValid from 'pesel-check'
import IconInput from '../components/IconInput'
import { Frame } from '../components/Frame'
import ReactSelect, { components } from 'react-select'
import { FaAddressCard } from 'react-icons/fa6'
import { AddressSelect } from '../components/AddressSelect'
import { FaBaby, FaTimes } from 'react-icons/fa'
import { ConfirmDialog, ConfirmDialogType } from '../components/ConfirmDialog'

type Profile = GetProfilesSubscription['client_profile'][number]

const ProfileEditForm: React.FC<{
  profile?: Profile
  onFinished?: () => void
}> = ({ profile, onFinished }) => {
  const params = useParams()
  const id = params.id ? parseInt(params.id) : undefined
  const basket = useContext(BasketContext)
  const auth = useContext(AuthContext)
  const i18n = useI18n()
  const [saveProfileFetching, setSaveProfileFetching] = useState(false)
  const [, addProfile] = useAddProfileMutation()
  const [, updateProfile] = useUpdateProfileMutation()
  const [genericError, setGenericError] = useState<string>()
  const confirmDialogRef = useRef<ConfirmDialogType>(null)
  const [, deleteProfile] = useDeleteProfileMutation()

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<{
    firstName: string
    lastName: string
    phoneNumber: string
    identityType: string
    identityValue: string
    identityCountryCode: string
    identityDateOfBirth: string
    address: string
    postalCode: string
    city: string
    location: Point
  }>({
    defaultValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      phoneNumber: profile?.phoneNumber || '',
      identityType: profile?.identity?.type || 'pesel',
      identityValue: profile?.identity?.value || '',
      identityCountryCode: profile?.identity?.countryCode || '',
      identityDateOfBirth: profile?.identity?.dateOfBirth || '',
      address: profile?.address || '',
      postalCode: profile?.postalCode || '',
      city: profile?.city || '',
      location: profile?.location || { type: 'Point', coordinates: [0, 0] },
    },
  })

  const doSaveProfile = useCallback(
    async (profile: {
      firstName: string
      lastName: string
      phoneNumber: string
      identityType: string
      identityValue: string
      identityCountryCode: string
      identityDateOfBirth: string
      address: string
      postalCode: string
      city: string
      location: Point
    }) => {
      setGenericError(undefined)
      setSaveProfileFetching(true)

      try {
        let identity: ClientIdentity
        switch (profile.identityType) {
          case 'id_card':
            identity = {
              type: 'id_card',
              value: profile.identityValue,
              countryCode: profile.identityCountryCode,
              dateOfBirth: profile.identityDateOfBirth,
            }
            break
          case 'passport':
            identity = {
              type: 'passport',
              value: profile.identityValue,
              countryCode: profile.identityCountryCode,
              dateOfBirth: profile.identityDateOfBirth,
            }
            break
          case 'pesel':
          default:
            identity = {
              type: 'pesel',
              value: profile.identityValue,
            }
        }

        const { error } = id
          ? await updateProfile({
              firstName: profile.firstName,
              lastName: profile.lastName,
              address: profile.address,
              city: profile.city,
              postalCode: profile.postalCode,
              phoneNumber: profile.phoneNumber,
              location: profile.location,
              id,
              identity,
            })
          : await addProfile({
              firstName: profile.firstName,
              lastName: profile.lastName,
              address: profile.address,
              city: profile.city,
              postalCode: profile.postalCode,
              phoneNumber: profile.phoneNumber,
              location: profile.location,
              clientId: auth.clientId,
              identity,
            })

        if (error) {
          setGenericError(error.message)
          return
        }

        // refresh profile stored in basket
        if (id) {
          basket.onProfileUpdated({
            ...omit(profile, [
              'identityType',
              'identityValue',
              'identityCountryCode',
            ]),
            id,
            identity,
          })
        }

        reset()
        onFinished && onFinished()
      } finally {
        setSaveProfileFetching(false)
      }
    },
    [addProfile, auth.clientId, basket, id, onFinished, reset, updateProfile]
  )

  const IdentityTypeOptions = useMemo(
    () => [
      {
        value: 'id_card',
        label: i18n.t('common.identity.idCard'),
      },
      {
        value: 'passport',
        label: i18n.t('common.identity.passport'),
      },
    ],
    [i18n]
  )

  const IdentityCountryCodeOptions = useMemo(
    () =>
      Countries.map(({ name, flag, isoCode }) => ({
        labelWithFlag: `${flag} ${i18n.t(`common.country.${name}`)}`,
        label: i18n.t(`common.country.${name}`),
        value: isoCode,
        flag,
      })),
    [i18n]
  )

  const doCancel = useCallback(() => {
    reset()
    onFinished && onFinished()
  }, [onFinished, reset])

  const doDelete = useCallback(() => {
    if (!profile) {
      return
    }

    confirmDialogRef.current?.show({
      message: `Czy na pewno chcesz usunąć profil "${profile.firstName} ${profile.lastName}"?`,
      onSuccess: async () => {
        await deleteProfile({ id: profile.id })
        onFinished && onFinished()
      },
    })
  }, [deleteProfile, onFinished, profile])

  return (
    <div>
      {genericError && <p>{genericError}</p>}
      <form onSubmit={handleSubmit(doSaveProfile)}>
        <div className="md:flex">
          <div className="md:pr-36 flex-1">
            <div className="my-4">
              <IconInput
                leftIcon={<MdPerson size={24} color={Blue300} />}
                {...register('firstName', {
                  required: i18n.t(
                    'screens.profiles.form.firstName.rules.required'
                  ),
                })}
                placeholder={i18n.t(
                  'screens.profiles.form.firstName.placeholder'
                )}
              />
              {errors.firstName && (
                <p className="hl-input-error">{errors.firstName.message}</p>
              )}
            </div>
            <div className="my-4">
              <IconInput
                leftIcon={<MdPerson size={24} color={Blue300} />}
                {...register('lastName', {
                  required: i18n.t(
                    'screens.profiles.form.lastName.rules.required'
                  ),
                })}
                placeholder={i18n.t(
                  'screens.profiles.form.lastName.placeholder'
                )}
              />
              {errors.lastName && (
                <p className="hl-input-error">{errors.lastName.message}</p>
              )}
            </div>

            <Controller
              name="address"
              control={control}
              rules={{
                required: i18n.t(
                  'screens.profiles.form.address.rules.required'
                ),
              }}
              render={() => (
                <div className="my-4">
                  <AddressSelect
                    onChange={(address) => {
                      if (address) {
                        setValue('address', address.address!)
                        setValue('postalCode', address.postalCode!)
                        setValue('city', address.city!)
                        setValue('location', address.location!)
                      } else {
                        setValue('address', '')
                        setValue('postalCode', '')
                        setValue('city', '')
                        setValue('location', {
                          type: 'Point',
                          coordinates: [0, 0],
                        })
                      }
                    }}
                    value={
                      watch('address')
                        ? {
                            address: watch('address'),
                            city: watch('city'),
                            postalCode: watch('postalCode'),
                            location: watch('location'),
                          }
                        : undefined
                    }
                  />
                  {errors.address && (
                    <p className="hl-input-error">{errors.address.message}</p>
                  )}
                  {errors.postalCode && (
                    <p className="hl-input-error">
                      {errors.postalCode.message}
                    </p>
                  )}
                  {errors.city && (
                    <p className="hl-input-error">{errors.city.message}</p>
                  )}
                  {errors.location && (
                    <p className="hl-input-error">{errors.location.message}</p>
                  )}
                </div>
              )}
            />
          </div>
          <div className="md:pr-36 flex-1">
            {watch('identityType') === 'pesel' && (
              <div className="my-4">
                <IconInput
                  leftIcon={<MdAspectRatio size={24} color={Blue300} />}
                  {...register('identityValue', {
                    required: i18n.t(
                      'screens.profiles.form.pesel.rules.required'
                    ),
                    validate: (value) =>
                      isPeselValid(value) || watch('identityType') !== 'pesel'
                        ? undefined
                        : i18n.t('screens.profiles.form.pesel.rules.pattern'),
                  })}
                  placeholder={i18n.t(
                    'screens.profiles.form.pesel.placeholder'
                  )}
                />
                {errors.identityValue && (
                  <p className="hl-input-error">
                    {errors.identityValue.message}
                  </p>
                )}
              </div>
            )}

            <Controller
              name="identityType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label>
                    <Switch
                      checked={value !== 'pesel'}
                      onChange={(v) => onChange(v ? 'id_card' : 'pesel')}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      width={40}
                      height={24}
                      onColor={Green600}
                      className="align-middle mr-1"
                    />
                    <span className="text-xs">
                      {i18n.t('screens.profiles.form.pesel.noPesel')}
                    </span>
                  </label>
                  {errors.identityType && <p>{errors.identityType.message}</p>}
                </div>
              )}
            />

            {watch('identityType') !== 'pesel' && (
              <>
                <div className="my-4">
                  <Controller
                    name="identityType"
                    control={control}
                    rules={{
                      required: i18n.t(
                        'screens.profiles.form.identityType.rules.required'
                      ),
                    }}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        value={IdentityTypeOptions.find(
                          (identityType) => identityType.value === value
                        )}
                        isSearchable={false}
                        onChange={onChange}
                        options={IdentityTypeOptions}
                        classNames={{
                          input: () => '!p-0 !m-0',
                          control: () =>
                            '!shadow-none !rounded-none !border-t-0 !border-l-0 !border-r-0 !border-b !border-b-border !border-solid !border-opacity-50 !px-2 !py-2.5 !bg-transparent !text-lg',
                          valueContainer: () => '!p-0 !pl-7',
                          option: ({ isFocused, isSelected }) =>
                            `!text-blue-700 ${
                              isFocused ? '!bg-blue-200' : ''
                            } ${isSelected ? 'font-bold !bg-white' : ''}`,
                          dropdownIndicator: () => '!text-blue-300 !p-0',
                          indicatorSeparator: () => 'hidden',
                          clearIndicator: () => '!text-blue-300 !p-1',
                        }}
                        placeholder={i18n.t(
                          'screens.profiles.form.identityType.placeholder'
                        )}
                        components={{
                          ValueContainer: ({ children, ...props }) => (
                            <components.ValueContainer {...props}>
                              <FaAddressCard
                                size={24}
                                color={Blue300}
                                className="absolute"
                              />
                              {children}
                            </components.ValueContainer>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div className="my-4">
                  <Controller
                    name="identityCountryCode"
                    control={control}
                    rules={{
                      required: i18n.t(
                        'screens.profiles.form.identityCountryCode.rules.required'
                      ),
                    }}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        value={IdentityCountryCodeOptions.find(
                          (identityType) => identityType.value === value
                        )}
                        onChange={(option) => onChange(option?.value)}
                        options={IdentityCountryCodeOptions.map((option) => ({
                          ...option,
                          label: option.labelWithFlag,
                        }))}
                        maxMenuHeight={200}
                        classNames={{
                          input: () => '!p-0 !m-0',
                          control: () =>
                            '!shadow-none !rounded-none !border-t-0 !border-l-0 !border-r-0 !border-b !border-b-border !border-solid !border-opacity-50 !px-2 !py-2.5 !bg-transparent !text-lg',
                          valueContainer: () => '!p-0 !pl-7',
                          option: ({ isFocused, isSelected }) =>
                            `!text-blue-700 ${
                              isFocused ? '!bg-blue-200' : ''
                            } ${isSelected ? 'font-bold !bg-white' : ''}`,
                          dropdownIndicator: () => '!text-blue-300 !p-0',
                          indicatorSeparator: () => 'hidden',
                          clearIndicator: () => '!text-blue-300 !p-1',
                        }}
                        placeholder={i18n.t(
                          'screens.profiles.form.identityCountryCode.placeholder'
                        )}
                        components={{
                          ValueContainer: ({ children, ...props }) => {
                            const flag = IdentityCountryCodeOptions.find(
                              (option) => option.value === value
                            )?.flag
                            return (
                              <components.ValueContainer {...props}>
                                {flag ? (
                                  <div className="absolute text-xl">{flag}</div>
                                ) : (
                                  <MdFlag
                                    size={24}
                                    color={Blue300}
                                    className="absolute"
                                  />
                                )}
                                {children}
                              </components.ValueContainer>
                            )
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="my-4">
                  <IconInput
                    leftIcon={<MdAspectRatio size={24} color={Blue300} />}
                    {...register('identityValue', {
                      required: i18n.t(
                        'screens.profiles.form.identityValue.rules.required'
                      ),
                    })}
                    placeholder={i18n.t(
                      'screens.profiles.form.identityValue.placeholder'
                    )}
                  />
                  {errors.identityValue && (
                    <p className="hl-input-error">
                      {errors.identityValue.message}
                    </p>
                  )}
                </div>
                <div className="my-4">
                  <IconInput
                    leftIcon={<FaBaby size={24} color={Blue300} />}
                    type="date"
                    max={new Date().toISOString()}
                    {...register('identityDateOfBirth', {
                      required: i18n.t(
                        'screens.profiles.form.identityDateOfBirth.rules.required'
                      ),
                    })}
                  />
                </div>
              </>
            )}
            <div className="my-4">
              <IconInput
                leftIcon={<MdOutlineSmartphone size={24} color={Blue300} />}
                {...register('phoneNumber', {
                  required: i18n.t(
                    'screens.profiles.form.phoneNumber.rules.required'
                  ),
                  pattern: {
                    value: /^((00|\+)\d{1,3})? ?(\d[ -]?){9}$/,
                    message: i18n.t(
                      'screens.profiles.form.phoneNumber.rules.pattern'
                    ),
                  },
                })}
                placeholder={i18n.t(
                  'screens.profiles.form.phoneNumber.placeholder'
                )}
              />
              {errors.phoneNumber && (
                <p className="hl-input-error">{errors.phoneNumber.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-16 flex gap-2.5 justify-center md:justify-start">
          {profile?.id && !profile.isMain && (
            <button className="hl-button hl-gradient-red" onClick={doDelete}>
              <FaTimes size={24} color="#fff" />
            </button>
          )}
          <button
            className="hl-button md:ml-auto"
            type="submit"
            disabled={saveProfileFetching}
          >
            {profile
              ? i18n.t('screens.profiles.form.submit.saveChanges')
              : i18n.t('screens.profiles.form.submit.createNew')}
          </button>
          <button className="hl-button hl-gradient-red" onClick={doCancel}>
            {i18n.t('common.cancel')}
          </button>
        </div>
      </form>
      <ConfirmDialog ref={confirmDialogRef} />
    </div>
  )
}

export const ProfilesPage = () => {
  const [{ data: profilesData, fetching: profilesFetching }] =
    useGetProfilesSubscription()
  const auth = useContext(AuthContext)
  const client = useClient()
  const i18n = useI18n()
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const [, updateClientConsent] = useUpdateClientConsentMutation()
  const { handleSubmit, watch, reset, control } = useForm({
    defaultValues: {
      newsletterConsent: false,
      pushConsent: false,
    },
  })

  const onSubmit = useCallback(
    async function ({
      newsletterConsent,
      pushConsent,
    }: {
      newsletterConsent: boolean
      pushConsent: boolean
    }) {
      await updateClientConsent({
        id: auth.clientId,
        newsletterConsent,
        pushConsent,
      })
    },
    [updateClientConsent, auth.clientId]
  )

  useEffect(() => {
    if (auth.clientId) {
      client
        .query<GetClientQuery, GetClientQueryVariables>(GetClientDocument, {
          id: auth.clientId,
        })
        .toPromise()
        .then(({ data: clientData }) => {
          if (clientData?.client_by_pk) {
            reset(omit(clientData.client_by_pk, ['__typename', 'id']))
          }
        })
        .catch(console.error)
    }
  }, [auth.clientId, client, reset])

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, onSubmit])

  const profiles = profilesData?.client_profile || []

  return (
    <Frame>
      <div className="md:mx-10">
        <div className="text-blue-700 mb-5">
          <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
          {' > '}
          Moje Profile
        </div>

        <h1 className="mb-8 mt-5 text-2xl md:text-3xl font-bold flex items-center gap-2.5">
          Moje Profile
          {profilesFetching && (
            <TailSpin color={Blue600} width={20} height={20} />
          )}
        </h1>

        {!!profilesData && (
          <ul className="flex flex-col gap-5">
            {profiles.map((profile) => (
              <li className="hl-panel" key={profile.id}>
                <Disclosure defaultOpen={params.id === String(profile.id)}>
                  {({ open, close }) => (
                    <>
                      <Disclosure.Button
                        className="p-5 flex gap-2.5 md:gap-5 items-center w-full"
                        onClick={() => {
                          if (open) {
                            navigate(`/profiles`)
                          } else {
                            navigate(`/profiles/${profile.id}`)
                          }
                        }}
                      >
                        <MdPerson size={24} color={Blue300} />
                        <div className="mr-auto">
                          <p className="text-lg font-bold text-left leading-tight">
                            {profile.firstName} {profile.lastName}{' '}
                            {profile.isMain && (
                              <span className="text-base font-normal">
                                {i18n.t('screens.profiles.mainProfile')}
                              </span>
                            )}
                          </p>
                          {!isProfileComplete(profile) && (
                            <p className="text-red-700 text-xs md:hidden">
                              {i18n.t('screens.profiles.profileIncomplete')}
                            </p>
                          )}
                        </div>
                        {!isProfileComplete(profile) && (
                          <p className="text-red-700 text-xs hidden md:block">
                            {i18n.t('screens.profiles.profileIncomplete')}
                          </p>
                        )}
                        <MdKeyboardArrowRight
                          size={24}
                          color={Blue600}
                          className={open ? 'rotate-90 transform' : ''}
                        />
                      </Disclosure.Button>
                      <Transition
                        className="overflow-hidden"
                        enter="transition-all ease-in-out duration-[500ms]"
                        enterFrom="transform  max-h-0"
                        enterTo="transform  max-h-[1000px]"
                        leave="transition-all ease-in-out duration-[500ms]"
                        leaveFrom="transform  max-h-[1000px]"
                        leaveTo="transform  max-h-0"
                      >
                        <Disclosure.Panel className="p-4 md:p-6 border-t border-t-border border-opacity-20">
                          <ProfileEditForm
                            profile={profile}
                            onFinished={() => close()}
                          />
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </li>
            ))}
            <li className="hl-panel">
              <Disclosure>
                {({ close }) => (
                  <>
                    <Disclosure.Button className="py-2.5 px-4 md:p-5 flex gap-5 items-center w-full">
                      <div className="mr-auto md:ml-5">
                        <p className="text-base text-blue-700">Dodaj nowy</p>
                        <p className="text-3xl font-bold">Profil</p>
                      </div>
                      <img src={ProfileAddIcon} alt="Add profile" />
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[500ms]"
                      enterFrom="transform  max-h-0"
                      enterTo="transform  max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[500ms]"
                      leaveFrom="transform  max-h-[1000px]"
                      leaveTo="transform  max-h-0"
                    >
                      <Disclosure.Panel className="p-6 border-t border-t-border border-opacity-20">
                        <ProfileEditForm onFinished={() => close()} />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </li>
          </ul>
        )}

        <div className="m-5 mt-10">
          <Controller
            control={control}
            name="newsletterConsent"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <label className="flex items-center">
                  <Switch
                    checked={value}
                    onChange={onChange}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40}
                    height={24}
                    onColor={Green600}
                    className="mr-6"
                  />
                  <span className="text-xs text-blue-700">
                    {i18n.t('screens.profiles.newsletterConsent')}
                  </span>
                </label>
                {error && <p className="hl-input-error">{error.message}</p>}
              </>
            )}
          />
        </div>

        <div className="m-5">
          <Controller
            control={control}
            name="pushConsent"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <label className="flex items-center">
                  <Switch
                    checked={value}
                    onChange={onChange}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40}
                    height={24}
                    onColor={Green600}
                    className="mr-6"
                  />
                  <span className="text-xs text-blue-700">
                    {i18n.t('screens.profiles.pushConsent')}
                  </span>
                </label>
                {error && <p className="hl-input-error">{error.message}</p>}
              </>
            )}
          />
        </div>
      </div>
    </Frame>
  )
}
